import { gql } from '@apollo/client';

import apiServiceClient from '~/graphql/apiServiceClient';

const query = gql`
  query Config($configYaml: String!, $pipelineValues: [StringKeyVal!]) {
    buildConfig(configYaml: $configYaml, pipelineValues: $pipelineValues) {
      valid
      errors {
        message
      }
    }
  }
`;

interface Props {
  configYaml: string;
  pipelineValues: { key: string; val: string }[] | null;
}

interface ConfigError {
  message: string;
}

interface Data {
  buildConfig: {
    valid: boolean;
    errors: ConfigError[];
  };
}

const apiServiceBuildConfig = async ({ configYaml, pipelineValues }: Props) => {
  const result = await apiServiceClient.query<Data>({
    query,
    variables: { configYaml, pipelineValues },
  });

  return result.data.buildConfig;
};

export default apiServiceBuildConfig;
