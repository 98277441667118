import makeExceptionLogger from '@circleci/exception-logger';

const exceptionLogger = makeExceptionLogger({
  // TODO: the below should not be any and likely won't work
  checkIgnore: (_isUncaught, _args, payload: any) => {
    if (
      payload.body.message.extra.message == 'Non-nullable field was null.' &&
      (payload.custom.extraArgs[0].operationName == 'planV2' ||
        payload.custom.extraArgs[0].operationName == 'CreditsAvailable')
    ) {
      return true;
    } else {
      return false;
    }
  },
  ignoredMessages: ['^[OPTIMIZELY] Request error$'], // see https://docs.rollbar.com/docs/javascript#ignoring-specific-exception-messages
});

export default exceptionLogger;
