import ExceptionLogger from '~/utils/exceptionLogger';
import {
  isForbidden,
  isNetworkError,
  isNotFoundError,
} from '@circleci/web-ui-data/build/client/errors';

import { client } from '@circleci/web-ui-data';
import { VCSType, APIProjectInfo } from '~/graphql/shim/types';

type Fetch = typeof fetch;

const handleError = (
  error: Error,
  vcsType: VCSType,
  username: string,
  project: string,
) => {
  // We don't want to log an error in the event of a 403
  // where an authenticated user was not allowed authorized access, instead return a null
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/403
  if (isForbidden(error)) return;
  // Don't rollbar on 404s here
  if (isNotFoundError(error)) return;
  // Don't rollbar NetworkErrors either
  if (isNetworkError(error)) return;

  // This should only happen in local dev where the error response from the
  // REST API doesn't contain the correct CORS headers.
  ExceptionLogger.error('Error while fetching project', error, {
    vcsType,
    username,
    project,
  });
};

const fetchAPIProject = async (
  fetch: Fetch,
  apiKey: string,
  vcsType: VCSType,
  username: string,
  project: string,
): Promise<APIProjectInfo> => {
  const pathname = `/api/v1.1/project/${vcsType}/${username}/${project}/info`;

  try {
    const response = await client.apiFetcher({
      apiKey,
      fetch,
      pathname,
      callerName: 'fetchAPIProject',
    });
    const data = await response.json();

    return {
      exists: true,
      ...data,
    };
  } catch (error: unknown) {
    handleError(error as any, vcsType, project, username);
    if (isNotFoundError(error as any)) {
      return {
        exists: false,
        analytics_id: '',
        has_followers: false,
        scopes: [],
      };
    }
    return undefined as any;
  }
};

export default fetchAPIProject;
