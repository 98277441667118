import * as R from 'ramda';

import {
  APIV2WorkflowJob,
  APIWorkflowJobsResponse,
  WorkflowJob,
} from '~/graphql/shim/types';

const workflowJob = ({
  id,
  dependencies,
  name,
  job_number,
  status,
  started_at,
  stopped_at,
  type,
}: APIV2WorkflowJob): WorkflowJob => ({
  id,
  dependencies,
  name,
  buildNumber: job_number,
  status: R.toUpper(status),
  startedAt: started_at,
  stoppedAt: stopped_at,
  type,
});

const workflowJobs = R.pipe<
  [APIWorkflowJobsResponse],
  APIV2WorkflowJob[],
  WorkflowJob[]
>(R.prop('items'), R.map(workflowJob));

export default workflowJobs;
