import { mapScheduledTrigger } from '~/project-settings/packages/triggers/graphql/mappers/mapScheduledTrigger';
import { mapScheduledTriggerCreate } from '~/project-settings/packages/triggers/graphql/mappers/mapScheduledTriggerCreate';
import {
  APICreateTrigger,
  createScheduledTrigger as createScheduledTriggerMutator,
} from '~/project-settings/packages/triggers/graphql/mutators/createScheduledTrigger';
import { Deps } from '~/project-settings/packages/triggers/graphql/resolvers/types';
import { MutationToCreateScheduledTriggerArgs } from '~/project-settings/packages/triggers/graphql/types';

export const createScheduledTrigger = async (
  _root: any,
  { schedule, ...props }: MutationToCreateScheduledTriggerArgs,
  deps: Deps,
) => {
  const apiSchedule: APICreateTrigger = mapScheduledTriggerCreate(schedule);
  const scheduledTrigger = await createScheduledTriggerMutator({
    ...deps,
    ...props,
    schedule: apiSchedule,
  });
  return mapScheduledTrigger(scheduledTrigger);
};
