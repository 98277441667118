import { SchemaLink } from '@apollo/client/link/schema';
import unfetch from 'unfetch';
import * as cookies from 'js-cookie';

import schema from '~/graphql/shim/schema';
import windowHasFetch from '~/utils/windowHasFetch';

const link = new SchemaLink({
  context: () => ({
    apiKey: localStorage.getItem('CIRCLE_TOKEN') || cookies.get('circle-token'),
    fetch: windowHasFetch() ? window.fetch : unfetch,
  }),
  schema,
});

export default link;
