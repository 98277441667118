import { client } from '@circleci/web-ui-data';

import { ResponseMessage } from '~/project-settings/packages/triggers/graphql/types';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  id: string;
}

export const deleteScheduledTrigger = async ({
  apiKey,
  fetch,
  id,
}: Props): Promise<ResponseMessage> => {
  const pathname = `/api/v2/schedule/${id}`;

  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'DELETE',
    body: {},
  });

  return response.json();
};
