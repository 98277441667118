import {
  APIPipelinesAndWorkflowsResponse,
  VCSType,
} from '~/graphql/shim/types';
import getHostname from '~/utils/getHostname';

type Fetch = typeof fetch;

interface Props {
  fetch: Fetch;
  apiKey: string;
  vcsType: VCSType;
  username: string;
  project: string | undefined;
  branch?: string;
  filter?: string;
  pageToken?: string;
  createdBefore?: string;
  isBffV2Enabled?: boolean;
}

export const fetchAPIPipelinesAndWorkflows = async ({
  fetch,
  vcsType,
  username,
  project,
  branch,
  filter,
  pageToken,
  createdBefore,
  isBffV2Enabled,
}: Props): Promise<APIPipelinesAndWorkflowsResponse> => {
  const BFF_BASE = isBffV2Enabled
    ? `https://bff.${getHostname()}/private/web-ui-service/v2`
    : `https://bff.${getHostname()}/private/web-ui-service`;
  let URL = '';
  const orgSlug = `${vcsType}/${username}`;

  const data: Record<string, string | undefined> = {
    mine: filter === 'mine' && !project ? 'true' : undefined,
    'org-slug': !project ? orgSlug : undefined,
    next_page_token: pageToken,
    branch: branch,
    created_before: createdBefore,
  };

  const filteredData = Object.fromEntries(
    Object.entries(data).filter(([_, v]) => v != undefined),
  );

  const searchParams = new URLSearchParams(
    filteredData as Record<string, string>,
  );

  if (project) {
    // Single project pipelines page
    if (filter === 'mine') {
      URL = `${BFF_BASE}/project/${vcsType}/${username}/${project}/pipeline/mine?${searchParams.toString()}`;
    } else {
      URL = `${BFF_BASE}/project/${vcsType}/${username}/${project}/pipeline?${searchParams.toString()}`;
    }
  } else {
    URL = `${BFF_BASE}/pipelines?${searchParams.toString()}`;
  }

  const requestOptions: RequestInit = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };

  try {
    const response = await fetch(URL, requestOptions);
    if (!response.ok) {
      throw `Response received was not ok: ${response.status}`;
    }

    return await response.json();
  } catch (e) {
    return {
      items: [],
      next_page_token: '',
    };
  }
};
