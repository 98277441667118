import { Forbidden } from '@circleci/web-ui-data/build/client/errors';

import { VCSType } from '~/graphql/shim/types';
import fetchAPIRepos from '~/engagement-experiments/graphql/shim/fetchers/repos';
import fetchSeenRepos from '~/engagement-experiments/graphql/shim/fetchers/seenRepos';
import fetchAPIServiceBuildConfig from '~/engagement-experiments/graphql/shim/fetchers/apiServiceBuildConfig';
import mapAPIRepo from '~/engagement-experiments/graphql/shim/mappers/repo';
import updateConfig from '~/engagement-experiments/graphql/shim/mutators/updateConfig';
import setupProject from '~/engagement-experiments/graphql/shim/mutators/setupProject';
import userRole from './fetchers/userRole';

interface UpdateConfigProps {
  vcsType: VCSType;
  org: string;
  name: string;
  config: string;
  branchName: string;
  commitMessage: string;
  commitSha: string;
}

interface SetupProjectProps {
  vcsType: VCSType;
  org: string;
  name: string;
  config: string;
  branch?: string;
  message?: string;
}

interface Deps {
  apiKey: string;
  fetch: typeof window.fetch;
}

interface BuildConfigProps {
  configYaml: string;
  pipelineValues: { key: string; val: string }[] | null;
}

const resolvers = {
  Query: {
    buildConfig: async (_root: unknown, props: BuildConfigProps) =>
      await fetchAPIServiceBuildConfig(props),
    gitHubRepos: async (
      _root: unknown,
      { page }: { page: number },
      { fetch, apiKey }: Deps,
    ) => {
      try {
        const apiRepos = await fetchAPIRepos(fetch, apiKey, {
          page,
          vcsType: 'github',
        });
        const seenRepos = fetchSeenRepos();
        return apiRepos.map((repo) => mapAPIRepo(repo, seenRepos));
      } catch (error) {
        if (error instanceof Forbidden) {
          return null;
        }
        throw error;
      }
    },

    userRole: async (_root: unknown, _args: {}, { fetch, apiKey }: Deps) => {
      return await userRole({ fetch, apiKey });
    },
  },
  Mutation: {
    validateConfig: async (_root: unknown, props: BuildConfigProps) =>
      await fetchAPIServiceBuildConfig(props),
    updateConfig: async (
      _root: any,
      {
        vcsType,
        org,
        name,
        config,
        branchName,
        commitMessage,
        commitSha,
      }: UpdateConfigProps,
      { fetch, apiKey }: Deps,
    ) => {
      await updateConfig(
        fetch,
        apiKey,
        vcsType,
        org,
        name,
        config,
        branchName,
        commitMessage,
        commitSha,
      );
    },
    setupProject: async (
      _root: any,
      { vcsType, org, name, config, branch, message }: SetupProjectProps,
      { fetch, apiKey }: Deps,
    ) => {
      await setupProject(
        fetch,
        apiKey,
        vcsType,
        org,
        name,
        config,
        branch,
        message,
      );
    },
  },
};

export default resolvers;
