import { useKBar, Priority } from 'kbar';
import { DocsIcon, SearchIcon } from '@circleci/compass';
import React, { useState } from 'react';

export default function useDocsActions() {
  const [seachquery, setSearchQuery] = useState('');
  const { query: kbarQuery } = useKBar((state) => {
    if (seachquery !== state.searchQuery) {
      setSearchQuery(state.searchQuery);
    }
  });
  const actions = [
    {
      id: `docs`,
      name: 'Docs',
      shortcut: ['d'],
      icon: <DocsIcon />,
      keywords: 'docs',
      priority: Priority.HIGH,
      section: 'Help',
      perform: () => window.open('https://circleci.com/docs/'),
    },
    {
      id: `v2-api-docs`,
      parent: 'docs',
      name: 'V2 API Docs',
      shortcut: ['v 2'],
      keywords: 'v2-api-docs',
      perform: () => window.open('https://circleci.com/docs/api/v2'),
    },
    {
      id: `search-Docs`,
      parent: 'docs',
      name: `${seachquery}` + ' (search)',
      icon: <SearchIcon />,
      perform: () =>
        window.open(
          'https://circleci.com/docs/?documentation%5Bquery%5D=' +
            `${encodeURI(seachquery)}`,
        ),
    },
  ];

  kbarQuery.registerActions(actions);
}
