import { client } from '@circleci/web-ui-data';

import { WebhookCreateInput } from '~/project-settings/graphql/types';
import { APIWebhook } from '~/project-settings/graphql/types/webhook';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  webhook: WebhookCreateInput;
}

const mutateCreateWebhook = async ({
  apiKey,
  fetch,
  webhook: { signing_secret, verify_tls, ...webhook },
}: Props): Promise<APIWebhook> => {
  const pathname = `/api/v2/webhook`;
  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'POST',
    body: {
      ...webhook,
      // /api/v2 input keys are kebab-case by convention
      'signing-secret': signing_secret,
      'verify-tls': verify_tls,
    },
  });
  return await response.json();
};
export default mutateCreateWebhook;
