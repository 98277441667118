import { fetchScheduledTrigger } from '~/project-settings/packages/triggers/graphql/fetchers/fetchScheduledTrigger';
import { mapScheduledTrigger } from '~/project-settings/packages/triggers/graphql/mappers/mapScheduledTrigger';
import { Deps } from '~/project-settings/packages/triggers/graphql/resolvers/types';
import { QueryToScheduledTriggerArgs } from '~/project-settings/packages/triggers/graphql/types';

export const getScheduledTrigger = async (
  _root: any,
  props: QueryToScheduledTriggerArgs,
  deps: Deps,
) => {
  const scheduledTrigger = await fetchScheduledTrigger({ ...deps, ...props });
  return mapScheduledTrigger(scheduledTrigger);
};
