import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
  keyType: 'deploy' | 'user';
}

const getType = ({ vcsType, keyType }: Pick<Props, 'vcsType' | 'keyType'>) => {
  if (keyType === 'deploy') {
    return 'deploy-key';
  }
  return `${vcsType}-user-key`;
};

const mutateCreateCheckoutKey = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
  keyType,
}: Props) => {
  const type = getType({ vcsType, keyType });
  const pathname = `/api/v1.1/project/${vcsType}/${orgName}/${projectName}/checkout-key`;
  await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'POST',
    body: { type },
  });
};

export default mutateCreateCheckoutKey;
