import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import { Collaborations } from '~/graphql/shim/types';

const query = gql`
  query Collaborations {
    collaborations {
      id
      name
      avatarUrl
      superAdmin
      vcsType
      slug
    }
  }
`;

interface Result {
  error?: Error;
  loading: boolean;
  collaborations?: Collaborations;
}

const useCollaborations = (): Result => {
  const { error, loading, data } = useQuery(query);

  const collaborations = data?.collaborations;

  return {
    error,
    loading,
    collaborations,
  };
};

export default useCollaborations;
