import { ParsedUrlQuery } from 'querystring';
import { trackAction } from '@circleci/analytics';
import * as R from 'ramda';
import getHostname from '~/utils/getHostname';

interface Props {
  query: ParsedUrlQuery;
  referrer: string;
  pathname: string;
}

const trackShared = ({ query, referrer, pathname }: Props) => {
  if (referrer.startsWith(`https://${getHostname()}`)) return;
  if (referrer.startsWith(`https://app.${getHostname()}`)) {
    return;
  }
  if (referrer.startsWith('https://github.com')) return;
  if (referrer.startsWith('https://bitbucket.org')) return;
  if (
    performance.navigation &&
    performance.navigation.type === performance.navigation.TYPE_RELOAD
  ) {
    return;
  }
  if (R.has('utm_source', query)) return;
  trackAction('shared-link', { pathname });
};

export default trackShared;
