import * as R from 'ramda';

import { APIOrgJob, OrgJob, Actor } from '~/graphql/shim/types';

interface MappedOrgJob
  extends Pick<
    OrgJob,
    | 'branch'
    | 'buildNumber'
    | 'commitSubject'
    | 'name'
    | 'startedAt'
    | 'status'
    | 'stoppedAt'
    | 'repoName'
  > {
  actor: Pick<Actor, 'avatarUrl' | 'name'>;
}

const getJobName = ({
  workflows,
  job_name,
}: Pick<APIOrgJob, 'job_name' | 'workflows'>) => {
  if (R.isNil(workflows)) return job_name;
  return workflows.job_name;
};

const mapOrgJob = ({
  branch,
  build_num,
  job_name,
  reponame,
  start_time,
  status,
  stop_time,
  subject,
  user,
  workflows,
}: APIOrgJob): MappedOrgJob => ({
  actor: { avatarUrl: user.avatar_url, name: user.name },
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  branch: branch!,
  buildNumber: build_num,
  commitSubject: subject,
  name: getJobName({ workflows, job_name })!,
  startedAt: start_time!,
  status: R.toUpper(status),
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  stoppedAt: stop_time!,
  repoName: reponame,
});

const mapAPIOrgJobsToGraphQL = R.map(mapOrgJob);
export default mapAPIOrgJobsToGraphQL;
