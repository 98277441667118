import { client } from '@circleci/web-ui-data';
import { VCSType } from '~/graphql/shim/types';
import { APISalientTrendResp } from '~/insights-experiments/graphql/shim/types';

export interface SalientTrendArgs {
  fetch: any;
  apiKey: string;
  vcsType: VCSType;
  orgName: string;
  projectName: string;
  workflowName?: string;
  branch?: string;
  allBranches?: boolean;
}
const fetchMostSalientTrend = async ({
  fetch,
  apiKey,
  vcsType,
  orgName,
  projectName,
  workflowName,
  branch,
  allBranches,
}: SalientTrendArgs): Promise<APISalientTrendResp> => {
  const pathname = `api/private/v2/insights/${vcsType}/${orgName}/${projectName}/most-salient-trend`;
  const query = {
    ...(workflowName && { 'workflow-name': workflowName }),
    ...(branch && { branch }),
    ...(allBranches && { 'all-branches': allBranches.toString() }),
  };

  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    query,
  });

  return await response.json();
};

export default fetchMostSalientTrend;
