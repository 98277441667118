import { client } from '@circleci/web-ui-data';
import * as R from 'ramda';

import { APIV2ItemsResponse } from '~/project-settings/graphql/types/apiv2';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
}

export interface ApiEnvironmentVariable {
  name: string;
  value: string;
  created_at?: string;
}

export type APIEnvironmentVariables =
  APIV2ItemsResponse<ApiEnvironmentVariable> & {
    next_page_token: string;
  };

const fetchUserOrganizations = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
}: Props): Promise<ApiEnvironmentVariable[]> => {
  const pathname = `/api/v2/project/${vcsType}/${orgName}/${projectName}/envvar`;
  let nextPageToken: string | null = null;
  let items: ApiEnvironmentVariable[] = [];

  do {
    const query = R.reject(R.isNil, { 'page-token': nextPageToken });
    const response = await client.apiFetcher({
      apiKey,
      fetch,
      pathname,
      query,
    });
    const body: APIEnvironmentVariables = await response.json();
    nextPageToken = body.next_page_token;
    items = R.concat(items, body.items);
  } while (nextPageToken);

  return items;
};

export default fetchUserOrganizations;
