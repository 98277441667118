import { client } from '@circleci/web-ui-data';

import { APIV2ItemsResponse } from '~/project-settings/graphql/types/apiv2';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
}

export interface CheckoutKey {
  public_key: string;
  type: string;
  fingerprint: string;
  preferred: boolean;
  created_at: string;
}

export type APICheckoutKeys = APIV2ItemsResponse<CheckoutKey>;

const query = {
  digest: 'sha256',
};

const fetchCheckoutKeys = async ({
  apiKey,
  fetch,
  projectName,
  vcsType,
  orgName,
}: Props): Promise<APICheckoutKeys> => {
  const pathname = `/api/v2/project/${vcsType}/${orgName}/${projectName}/checkout-key`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname, query });
  return await response.json();
};

export default fetchCheckoutKeys;
