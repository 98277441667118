import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

export type VCSType = 'bitbucket' | 'github';

export interface APIV1Project {
  vcs_type: VCSType;
  username: string;
  reponame: string;
}

interface Props {
  apiKey: string;
  fetch: Fetch;
}

const fetchProjects = async ({ apiKey, fetch }: Props) => {
  const pathname = `/api/v1/projects`;
  const query = { shallow: 'true' };

  const response = await client.apiFetcher({ apiKey, fetch, pathname, query });
  return (await response.json()) as APIV1Project[];
};

export default fetchProjects;
