import React from 'react';
import { Flex } from '@circleci/compass';
import { CmdK } from '~/components/CmdK/CmdK';
import { FlexProps } from '@circleci/compass/dist/Flex';

interface Props extends FlexProps {
  children: React.ReactNode;
}

/**
 * Component to have all things the content portion of page have in common
 */
export const PageContent = ({ children, ...rest }: Props) => {
  return (
    <Flex direction="column" width={'100%'} backgroundColor={'n40'} {...rest}>
      <CmdK>{children}</CmdK>
    </Flex>
  );
};
