import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
  name: string;
  value: string;
}

const mutateAddEnvironmentVariable = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
  name,
  value,
}: Props) => {
  const pathname = `/api/v2/project/${vcsType}/${orgName}/${projectName}/envvar`;
  await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'POST',
    body: { name, value },
  });
};
export default mutateAddEnvironmentVariable;
