import { useQuery } from '@tanstack/react-query';
import unfetch from 'unfetch';

import windowHasFetch from '~/utils/windowHasFetch';
import getHostname from '~/utils/getHostname';
import { asError } from '~/engagement-experiments/utils/errors';
import { trackAction } from '@circleci/analytics';

interface Props {
  vcsType: string;
  orgName: string;
  enabled?: boolean;
}

interface Project {
  total: number;
  total_with_builds: number;
  last_build_finished_at: string; // ISO timestamp string like 2024-08-15T22:31:33.612Z
}

export interface Response {
  id: string;
  name: string;
  slug: string;
  type: string;
  projects: Project;
}

/**
 * `useCurrentOrganization` returns the analytics-id of the organization indicated by
 * the `vcsType` & `orgName`. This is opposed to the behavior of `useOrganizationId`, which
 * fetches the analytics-id of the plan-owning organization. For example, `useOrganizationId`
 * will return the same analytics-id for `github/circleci` and `github/CircleCI-Public`.
 * `useCurrentOrganization` will return a different analytics-id for each one.
 */
export const useCurrentOrganization = ({
  vcsType,
  orgName,
  enabled = true,
}: Props) => {
  const url = `https://${getHostname()}/api/private/organization/${vcsType}%2F${orgName}`;
  // defining fetch inside of the hook so that it can be evaluated at call time.
  // this makes it much easier to mock out in our tests.
  const fetch = windowHasFetch() ? window.fetch : unfetch;
  const {
    isInitialLoading: loading,
    error,
    data,
  } = useQuery<Response>(
    [url],
    async () => {
      const res = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        cache: 'force-cache',
      });

      if (!res.ok) {
        trackAction('Error retrieving current organizationId', {
          status_code: res.status,
        });
        const message = `Received non 2xx from /api/private/organization/:vcsType%2F:orgName: ${res.status}`;
        const error = new Error(message);
        Object.assign(error, { status: res.status });
        throw error;
      }

      return await res.json();
    },
    {
      enabled,
    },
  );

  return {
    error: asError(error),
    loading,
    organization: data,
  };
};
