import { APIProjectBranches, ProjectBranches } from '~/graphql/shim/types';

// Branches must be provided with a unique ID to be used in Combobox elements
const projectBranches = (branches: APIProjectBranches): ProjectBranches => {
  return {
    items: branches.items.map((i) => {
      return { name: i.name, id: i.name };
    }),
    default: branches.default,
  };
};

export default projectBranches;
