import { client } from '@circleci/web-ui-data';

interface Props {
  fetch: typeof window.fetch;
  apiKey: string;
  vcsType: string;
  orgName: string;
  projectName: string;
}

const mutateDeleteAWSVars = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
}: Props) => {
  const pathname = `/api/v1.1/project/${vcsType}/${orgName}/${projectName}/settings`;
  await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'PUT',
    body: { aws: { keypair: null } },
  });
};

export default mutateDeleteAWSVars;
