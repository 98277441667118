import { APIV1Project } from '~/project-settings/graphql/shim/fetchers/projects';

const mapProjects = (
  projects: APIV1Project[],
  targetOrg: string,
  targetVcs: string,
) =>
  projects
    .filter(
      (project) =>
        project.username === targetOrg && project.vcs_type === targetVcs,
    )
    .map((project) => ({
      name: project.reponame,
    }));

export default mapProjects;
