import * as React from 'react';
import { CancelIcon, useCompassTheme } from '@circleci/compass';
import { css } from '@emotion/core';

import styled from '~/styled';
import { mediaDesktop } from '~/components/shared-components/Viewports';
import { Button as CompassButton, Paragraph } from '@circleci/compass';

export const ToastContent = styled(Paragraph)`
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 544px;
  padding-left: ${({ theme }) => theme.space.space4};
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.legacy.space[2]}px;
`;

// dark background not available as a Compass button
const Button = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  &:hover {
    background: transparent !important;
  }
`;

type Props = React.ButtonHTMLAttributes<HTMLButtonElement & HTMLAnchorElement>;

export const ToastCloseButton = (props: Props) => {
  const theme = useCompassTheme();
  return (
    <CloseButtonWrapper>
      <Button {...props}>
        <CancelIcon color={theme.legacy.colors.white} />
      </Button>
    </CloseButtonWrapper>
  );
};

export const CTAButton = styled(CompassButton)(
  ({ theme }) => css`
    border: none;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    font-weight: ${theme.legacy.fontWeights.bold};
    background-color: transparent;
    color: ${theme.colors.blue300};
    padding-left: ${theme.legacy.space[4]}px;
    &:hover:enabled {
      background: transparent;
    }
  `,
);

const Toast = styled.div(
  ({ theme }) => css`
    position: fixed;
    display: flex;
    background: ${theme.legacy.colors.neutral700};
    border-radius: ${theme.legacy.space[1]}px;
    padding: ${theme.legacy.space[3]}px ${theme.legacy.space[4]}px;
    align-items: center;
    transition: ease-in 500ms;
    bottom: ${theme.legacy.space[2]}px;
    right: 0;
    margin: 0 ${theme.legacy.space[2]}px;
    width: calc(100% - ${theme.legacy.space[4]}px);
    z-index: 5;

    ${mediaDesktop} {
      bottom: ${theme.legacy.space[6]}px;
      right: ${theme.legacy.space[6]}px;
      width: fit-content;
      min-width: 440px;
      max-width: calc(100% - 132px);
      margin: unset;
    }

    > div:first-of-type {
      margin-right: ${theme.legacy.space[2]}px;
    }

    > button {
      justify-content: flex-end;
    }
  `,
);

export default Toast;
