import * as R from 'ramda';

import { APILegacyJob, LegacyJob, Actor } from '~/graphql/shim/types';

interface MappedLegacyJob
  extends Pick<
    LegacyJob,
    | 'branch'
    | 'buildNumber'
    | 'commitSubject'
    | 'name'
    | 'startedAt'
    | 'status'
    | 'stoppedAt'
  > {
  actor: Pick<Actor, 'avatarUrl' | 'name'>;
}

const getJobName = ({
  workflows,
  job_name,
}: Pick<APILegacyJob, 'job_name' | 'workflows'>) => {
  if (R.isNil(workflows)) return job_name;
  return workflows.job_name;
};

const mapLegacyJob = ({
  branch,
  build_num,
  job_name,
  start_time,
  status,
  stop_time,
  subject,
  user,
  workflows,
}: APILegacyJob): MappedLegacyJob => ({
  actor: { avatarUrl: user.avatar_url, name: user.name },
  branch: branch as any,
  buildNumber: build_num,
  commitSubject: subject,
  name: getJobName({ workflows, job_name }) as any,
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  startedAt: start_time!,
  status: R.toUpper(status),
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  stoppedAt: stop_time!,
});

const mapAPILegacyJobsToGraphQL = R.map(mapLegacyJob);

export default mapAPILegacyJobsToGraphQL;
