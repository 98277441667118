import { VCSType, APILegacyJob } from '~/graphql/shim/types';
import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string | undefined;
  fetch: typeof window.fetch;
  vcsType: VCSType;
  username: string;
  project: string;
  offset: number;
}

const fetchAPILegacyJobs = async ({
  apiKey,
  fetch,
  vcsType,
  username,
  project,
  offset,
}: Props) => {
  const pathname = `/api/v1.1/project/${vcsType}/${username}/${project}`;

  const res = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    query: { offset: `${offset}`, limit: '100' },
    callerName: 'legacyJobs',
  });
  return res.json() as unknown as Pick<
    APILegacyJob,
    | 'branch'
    | 'build_num'
    | 'start_time'
    | 'status'
    | 'stop_time'
    | 'subject'
    | 'user'
    | 'workflows'
  >[];
};

export default fetchAPILegacyJobs;
