import { gql } from '@apollo/client';
import apiServiceClient from '~/graphql/apiServiceClient';

export enum PlanTier {
  CONTAINER = 'CONTAINER',
  CUSTOM_CONTAINER = 'CUSTOM_CONTAINER',
  CUSTOM_PERFORMANCE = 'CUSTOM_PERFORMANCE',
  FREE = 'FREE',
  GITHUB_MARKETPLACE = 'GITHUB_MARKETPLACE',
  PERFORMANCE = 'PERFORMANCE',
  SCALE = 'SCALE',
}

const query = gql`
  query planV2($orgName: String!, $vcsType: VCSType!) {
    organization(name: $orgName, vcsType: $vcsType) {
      planV2 {
        tier
      }
    }
  }
`;

export interface PlanV2 {
  tier: PlanTier | undefined;
}

interface Organization {
  planV2: PlanV2;
}

export interface PlanV2Response {
  organization: Organization;
}

interface Props {
  orgName: string;
  vcsType: string;
}

const fetchPlanV2 = async ({ orgName, vcsType }: Props) => {
  const response = await apiServiceClient.query<PlanV2Response>({
    query,
    variables: {
      orgName,
      vcsType,
    },
  });

  return response?.data;
};

export default fetchPlanV2;
