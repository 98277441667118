import { client } from '@circleci/web-ui-data';

import {
  APIParameters,
  APIScheduledTrigger,
} from '~/project-settings/packages/triggers/graphql/fetchers/fetchScheduledTrigger';
import {
  Week,
  InputTriggerActor,
  Month,
} from '~/project-settings/packages/triggers/graphql/types';

export interface InputTimetable {
  'days-of-week'?: Week[];
  'hours-of-day': number[];
  'per-hour': number;
  'days-of-month'?: number[];
  months?: Month[];
}
export interface APICreateTrigger {
  name: string;
  description?: string;
  'attribution-actor': Omit<InputTriggerActor, 'previous'>;
  timetable: InputTimetable;
  parameters: APIParameters;
}

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
  schedule: APICreateTrigger;
}

export const createScheduledTrigger = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
  schedule,
}: Props): Promise<APIScheduledTrigger> => {
  const pathname = `/api/v2/project/${vcsType}/${orgName}/${projectName}/schedule`;
  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'POST',
    body: schedule,
  });
  return await response.json();
};
