import * as R from 'ramda';
import * as url from 'url';

import { VCSType } from '~/graphql/shim/types';
import getHostname from '~/utils/getHostname';

interface ParsedStepId {
  parallelRunIndex: number;
  stepIndex: number;
}

export const parseStepSlug = (stepSlug: string): ParsedStepId => {
  if (!R.test(/^\d+-\d+$/, stepSlug)) {
    throw new Error(
      `stepSlug must match "^\\d+-\\d+$", received "${stepSlug}"`,
    );
  }

  const [parallelRunIndexStr, stepIndexStr] = R.split('-', stepSlug);

  return {
    parallelRunIndex: Number(parallelRunIndexStr),
    stepIndex: Number(stepIndexStr),
  };
};

interface DownloadUrlProps {
  vcsType: VCSType;
  username: string;
  project: string;
  buildNumber: number;
  parallelRunIndex: number;
  stepIndex: number;
  allocationId: string | undefined;
}

export const downloadUrl = ({
  vcsType,
  username,
  project,
  buildNumber,
  parallelRunIndex,
  stepIndex,
  allocationId,
}: DownloadUrlProps) =>
  url.format({
    protocol: 'https:',
    hostname: getHostname(),
    pathname: `/api/v1.1/project/${vcsType}/${username}/${project}/${buildNumber}/output/${stepIndex}/${parallelRunIndex}`,
    query: { file: 'true', 'allocation-id': allocationId },
  });
