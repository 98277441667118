import { client } from '@circleci/web-ui-data';
interface Props {
  vcsType: string;
  orgName: string;
  projectName: string;
  fetch: typeof window.fetch;
  apiKey: string;
}

type FeatureFlagName =
  | 'build-prs-only'
  | 'set-github-status'
  | 'forks-receive-secret-env-vars'
  | 'build-fork-prs'
  | 'autocancel-builds'
  | 'oss'
  | 'setup-workflows';

type FeatureFlag = Partial<{ [key in FeatureFlagName]: boolean }>;

const mutateUpdateAdvancedSetting = async (
  { apiKey, fetch, vcsType, orgName, projectName }: Props,
  featureFlag?: FeatureFlag,
  jiraToken?: string,
) => {
  const pathname = `/api/v1.1/project/${vcsType}/${orgName}/${projectName}/settings`;
  const getBody = () => {
    if (jiraToken) {
      return { jira: { circle_token: jiraToken } };
    }
    return {
      feature_flags: {
        ...featureFlag,
      },
    };
  };
  await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'PUT',
    body: getBody(),
  });
};

export default mutateUpdateAdvancedSetting;
