import { client } from '@circleci/web-ui-data';
import { APIRepo } from '~/engagement-experiments/graphql/shim/types';
import { VCSType } from '~/graphql/shim/types';

interface Params {
  page?: number;
  vcsType: VCSType;
}

const repos = async (
  fetch: any,
  apiKey: string | undefined,
  { page, vcsType }: Params,
): Promise<APIRepo[]> => {
  const pathname = `/api/v1.1/user/repos/${vcsType}`;
  const rawResponse = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    query: page ? { page: page.toString(), 'per-page': '100' } : {},
  });
  return await rawResponse.json();
};

export default repos;
