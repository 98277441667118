import * as url from 'url';

import * as R from 'ramda';

import global from '~/project-settings/utils/global';
import isServer from '~/project-settings/utils/isServer';

const sanitizeReturnTo = (returnTo: string) => {
  /*This is necessary because server side utilizes this to check the route */
  const hostname =
    (isServer && process.env.CIRCLECI_HOSTNAME) ||
    global.circleci?.config?.hostname ||
    null;
  if (!hostname) {
    throw new Error('CircleCI hostname is not configured');
  }

  const allowedHosts = [
    `ui.${hostname}`,
    `app.${hostname}`,
    `app-canary.${hostname}`,
    `app-experiment.${hostname}`,
  ];
  const hostIsNotAllowed = (host: string | null) =>
    R.not(R.includes(host, allowedHosts));
  if (!returnTo) {
    return;
  }

  const { protocol, host } = url.parse(returnTo);

  if (protocol !== 'https:') {
    return;
  }
  if (hostIsNotAllowed(host)) {
    return;
  }

  return returnTo;
};

export default sanitizeReturnTo;
