import { client } from '@circleci/web-ui-data';
import * as R from 'ramda';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;

  vcsType: string;
  orgName: string;
  fromProjectName: string;
  toVcsUrl: string;
  environmentVariableNames: string[];
}

type PathProps = Pick<Props, 'vcsType' | 'orgName' | 'fromProjectName'>;

const pathname = (props: PathProps) => {
  const { vcsType, orgName, fromProjectName } = R.mapObjIndexed(
    encodeURIComponent,
    props,
  );
  return `/api/v1.1/project/${vcsType}/${orgName}/${fromProjectName}/info/export-environment`;
};

const importEnvironmentVariables = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  toVcsUrl,
  fromProjectName,
  environmentVariableNames,
}: Props) =>
  await client.apiMutator({
    apiKey,
    fetch,
    pathname: pathname({ vcsType, orgName, fromProjectName }),
    body: {
      projects: [toVcsUrl],
      'env-vars': environmentVariableNames,
    },
    method: 'POST',
  });

export default importEnvironmentVariables;
