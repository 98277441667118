import { VCSType, APIPipelinesResponse } from '~/graphql/shim/types';
import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

interface Props {
  fetch: Fetch;
  apiKey: string;
  vcsType: VCSType;
  username: string;
  filter?: string;
  pageToken?: string;
}

const pipelinesQuery = ({
  vcsType,
  username,
  filter,
  pageToken,
}: Pick<Props, 'vcsType' | 'username' | 'pageToken' | 'filter'>) => {
  let query = {};

  if (pageToken) query = { ...query, 'page-token': pageToken };
  if (filter === 'mine') query = { ...query, mine: true };

  return { ...query, 'org-slug': `${vcsType}/${username}` };
};

const fetchCrossProjectPipelines = async ({
  fetch,
  apiKey,
  vcsType,
  username,
  filter,
  pageToken,
}: Props): Promise<APIPipelinesResponse> => {
  const pathname = `/api/v2/pipeline`;
  const query = pipelinesQuery({ vcsType, username, filter, pageToken });
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    query,
    callerName: 'fetchCrossProjectPipelines',
  });

  return await response.json();
};

export default fetchCrossProjectPipelines;
