import { APIProjectSettings } from '~/project-settings/graphql/shim/fetchers/projectSettings';

type APIAdvancedSettings = Pick<APIProjectSettings, 'feature_flags'>;

const mapAdvancedSettings = ({ feature_flags }: APIAdvancedSettings) => ({
  setGithubStatus: feature_flags['set-github-status'],
  buildForkPrs: feature_flags['build-fork-prs'],
  forksReceiveSecretEnvVars: feature_flags['forks-receive-secret-env-vars'],
  buildPrsOnly: feature_flags['build-prs-only'],
  autoCancelBuilds: feature_flags['autocancel-builds'],
  oss: feature_flags['oss'],
  setupWorkflows: feature_flags['setup-workflows'],
});

export default mapAdvancedSettings;
