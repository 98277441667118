import { client } from '@circleci/web-ui-data';
import { VCSType } from '~/graphql/shim/types';
import { parseStepSlug } from '~/utils/step';

type Fetch = typeof window.fetch;

interface Props {
  apiKey: string;
  vcsType: VCSType;
  username: string;
  project: string;
  buildNumber: string;
  stepSlug: string;
  allocationId: string | undefined;
}

const fetchStepOutput = async (
  fetch: Fetch,
  {
    apiKey,
    vcsType,
    username,
    project,
    buildNumber,
    stepSlug,
    allocationId,
  }: Props,
) => {
  const { parallelRunIndex, stepIndex } = parseStepSlug(stepSlug);
  const pathname = `api/v1.1/project/${vcsType}/${username}/${project}/${buildNumber}/output/${stepIndex}/${parallelRunIndex}`;
  const query = allocationId ? { 'allocation-id': allocationId } : undefined;
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    query,
    callerName: 'fetchStepOutput',
  });
  return await response.json();
};

export default fetchStepOutput;
