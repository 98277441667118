import { PlanTier, PlanV2, PlanV2Response } from '../fetchers/planV2';

const parsePlanTier = (planV2: PlanV2): PlanV2 => {
  switch (planV2?.tier) {
    case PlanTier.CONTAINER:
    case PlanTier.CUSTOM_CONTAINER:
    case PlanTier.CUSTOM_PERFORMANCE:
    case PlanTier.FREE:
    case PlanTier.GITHUB_MARKETPLACE:
    case PlanTier.PERFORMANCE:
    case PlanTier.SCALE:
      break;
    default:
      planV2.tier = undefined;
  }
  return planV2;
};

const mapPlanV2 = (planTierResponse: PlanV2Response): PlanV2 => {
  return parsePlanTier(planTierResponse.organization.planV2);
};

export default mapPlanV2;
