import { APICheckoutKeys } from '~/project-settings/graphql/shim/fetchers/checkoutKeys';
import { CheckoutKey } from '~/project-settings/graphql/types';

const mapCheckoutKeys = (checkoutKeys: APICheckoutKeys): CheckoutKey[] => {
  const items = checkoutKeys.items;
  const checkoutKeysArray = items.map((checkoutKey) => ({
    publicKey: checkoutKey.public_key,
    type: checkoutKey.type,
    fingerprint: checkoutKey.fingerprint,
    preferred: checkoutKey.preferred,
    createdAt: checkoutKey.created_at,
  }));
  return checkoutKeysArray;
};

export default mapCheckoutKeys;
