import { VCSType, APITestResponse } from '~/graphql/shim/types';
import { client } from '@circleci/web-ui-data';

export interface TestResultsArgs {
  fetch: any;
  apiKey: string;
  vcsType: VCSType;
  username: string;
  project: string;
  buildNumber: string;
}

/**
 * testResults is an API wrapper around the v1.1 rest API endpoint for test
 * results. Requires the user's API Key, the Url components and the fetch
 * function. The fetch function can be a mock.
 */
const fetchAPITestResults = async ({
  fetch,
  apiKey,
  vcsType,
  username,
  project,
  buildNumber,
}: TestResultsArgs): Promise<APITestResponse> => {
  const pathname = `/api/v1.1/project/${vcsType}/${username}/${project}/${buildNumber}/tests`;

  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    callerName: 'fetchAPITestResults',
  });
  return response.json();
};

export default fetchAPITestResults;
