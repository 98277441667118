import { client } from '@circleci/web-ui-data';
import { APIConfig } from '~/graphql/shim/types';

type Fetch = typeof fetch;

const fetchConfig = async (
  fetch: Fetch,
  apiKey: string,
  pipelineId: string,
): Promise<APIConfig> => {
  const pathname = `/api/v2/pipeline/${pipelineId}/config`;
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    callerName: 'fetchConfig',
  });
  return await response.json();
};

export default fetchConfig;
