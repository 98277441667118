const typeDefs = `
type SalientTrend {
  type: String!
  metric: Float!
  trend: Float!
}

type ConfigRecommendationsLink {
  docs: String
  action: String
  details: String
}

type ConfigRecommendations {
  id: String
  orgId: String
  optimizationType: String
  optimizationCategory: String
  links: ConfigRecommendationsLink
  source: String
  projectId: String
  details: String!
  trigger: String
  headline: String!
}

extend type Query {
  salientTrends(vcsType: String!, orgName: String!, projectName: String!, workflowName: String, branch: String, allBranches: Boolean): SalientTrend!
  configRecommendations(vcsType: String!, orgName: String!, projectName: String!, workflowExecutionId: String!): ConfigRecommendations!
}`;

export default typeDefs;
