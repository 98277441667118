import * as R from 'ramda';
import { APIProjectInfo, ProjectInfo } from '~/graphql/shim/types';

const canTriggerBuilds = R.pipe<[APIProjectInfo], string[], boolean>(
  R.propOr([], 'scopes'),
  R.includes('trigger-builds'),
);

const mapAPIProjectInfoToGraphQL = (
  apiProject: APIProjectInfo,
): ProjectInfo => ({
  exists: apiProject?.exists ?? false,
  analyticsId: apiProject?.analytics_id ?? '',
  hasFollowers: apiProject?.has_followers ?? false,
  permissions: {
    triggerBuilds: canTriggerBuilds(apiProject),
    write: !R.isNil(apiProject),
    approveJobs: canTriggerBuilds(apiProject),
  },
});

export default mapAPIProjectInfoToGraphQL;
