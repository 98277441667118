import fetchCreditsAvailable, {
  FetchCreditsAvailable,
} from '~/buyer-experience-experiments/graphql/shim/fetchers/creditsAvailable';
import mapCreditsAvailable from '~/buyer-experience-experiments/graphql/shim/mappers/creditsAvailable';

const resolvers = {
  Query: {
    creditsAvailable: async (_root: unknown, args: FetchCreditsAvailable) => {
      const data = await fetchCreditsAvailable(args);
      return mapCreditsAvailable(data);
    },
  },
};

export default resolvers;
