import {
  APIParameters,
  APIParameterValue,
} from '~/project-settings/packages/triggers/graphql/fetchers/fetchScheduledTrigger';
import { APICreateTrigger } from '~/project-settings/packages/triggers/graphql/mutators/createScheduledTrigger';
import { APIUpdateTrigger } from '~/project-settings/packages/triggers/graphql/mutators/updateScheduledTrigger';
import {
  CreateTriggerInput,
  InputTriggerActor,
  ParameterType,
  UpdateTriggerInput,
} from '~/project-settings/packages/triggers/graphql/types';

export function mapScheduledTriggerCreate(
  inputTrigger: CreateTriggerInput,
): APICreateTrigger;
export function mapScheduledTriggerCreate(
  inputTrigger: UpdateTriggerInput,
): APIUpdateTrigger;

export function mapScheduledTriggerCreate(
  inputTrigger: UpdateTriggerInput | CreateTriggerInput,
): APICreateTrigger | APIUpdateTrigger {
  const {
    branchOrTag,
    actor,
    parameters,
    days_of_week,
    hours_of_day,
    per_hour,
    days_of_month,
    months,
    ...formSchedule
  } = inputTrigger;

  // @ts-ignore
  const apiParameters = parameters.reduce(
    // @ts-ignore
    (parametersMap, { name, type, value }) => {
      let castedValue: APIParameterValue = value;
      if (type === ParameterType.boolean) {
        castedValue = value === 'true';
      } else if (type === ParameterType.integer) {
        castedValue = parseInt(value);
      }
      parametersMap[name] = castedValue;
      return parametersMap;
    },
    {} as APIParameters,
  );

  // @ts-ignore
  apiParameters[branchOrTag.type] = branchOrTag.value;

  const apiScheduledTrigger = {
    ...formSchedule,
    parameters: apiParameters,
    timetable: {
      'hours-of-day': hours_of_day,
      'per-hour': per_hour,
      ...(days_of_week && { 'days-of-week': days_of_week }),
      ...(days_of_month && { 'days-of-month': days_of_month }),
      ...(months && { months: months }),
    },
  };

  // previous is not a valid option in the backend so we remove on updates
  // this results in the actor not changing in the update
  if (actor !== InputTriggerActor.previous) {
    // @ts-ignore
    apiScheduledTrigger['attribution-actor'] = actor;
  }

  // @ts-ignore
  return apiScheduledTrigger;
}
