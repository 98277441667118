import * as R from 'ramda';

import { VCSType } from '~/graphql/shim/types';

export const storageKey = 'add-project-dot-seen-repos';

export type SeenRepos = {
  [vcsType in VCSType]?: {
    [org: string]: {
      [name: string]: true;
    };
  };
};

interface RepoProps {
  vcsType: VCSType;
  org: string;
  name: string;
}

export const getSeenRepos = (): SeenRepos => {
  const valueStr = window.localStorage.getItem(storageKey);

  try {
    return JSON.parse(valueStr || '{}');
  } catch (error) {
    return {};
  }
};

export const setRepoSeen = ({ vcsType, org, name }: RepoProps) => {
  const value = R.assocPath([vcsType, org, name], true, getSeenRepos());
  const valueStr = JSON.stringify(value);

  window.localStorage.setItem(storageKey, valueStr);
};
