import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
}

export interface ApiApiToken {
  label: string;
  scope: string;
  token?: string;
  time: string;
  id: string;
}

const fetchApiTokens = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
}: Props): Promise<ApiApiToken[]> => {
  const pathname = `/api/v1.1/project/${vcsType}/${orgName}/${projectName}/token`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};

export default fetchApiTokens;
