import { Trigger, VCSType } from '@circleci/web-ui-data/build/utils/types';
import { ProjectV2 } from '@circleci/web-ui-data/build/queries/project/types';
import { TriggerParameters as TriggerParametersAPI } from '~/types/types';
import { ProjectSlug } from '@circleci/web-ui-data/build/types/types';

export type { VCSType, Trigger };

export interface APIConfig {
  compiled: string;
  source: string;
}

export interface APICollaboration {
  id: string | null;
  name: string;
  avatar_url: string;
  vcs_type: string;
  super_admin: boolean;
  slug: string;
}
export type APICollaborations = APICollaboration[];

export interface APIUser {
  avatar_url: string;
  id: number;
  is_user: boolean;
  login: string;
  name?: string;
  vcs_type: string;
}

export interface APIActor {
  avatar_url: string;
  login: string;
  name?: string;
  vcs_type?: string;
}

export interface Project {
  vcsType: VCSType;
  username: string;
  project: string;
}

export interface Collaboration {
  id: string | null;
  name: string;
  avatarUrl: string;
  vcsType: string;
  superAdmin: boolean;
  slug: string;
}
export type Collaborations = Collaboration[];
export interface APIAction {
  name: string;
  /** index indicates which parallelRun this belongs to */
  index: number;
  insignificant: boolean;
  run_time_millis: number;
  status: string;
  bash_command?: string;
  /** step indicates which step this is whithin the parallelRun */
  step: number;
  start_time?: string;
  end_time?: string;
  exit_code: number;

  /** allocation_id lets us detect when a parallel run had an infrastructure failure and reran a portion of its steps */
  allocation_id: string;
}

export interface APIPipeline {
  id: string;
  project_slug: ProjectSlug;
  state: string;
  created_at: string;
  number: number;
  workflows: {
    id: string;
  }[];
  vcs?: {
    branch: string;
    tag: string;
    revision: string;
    origin_repository_url: string;
    commit: {
      subject: string;
    };
  };
  trigger: {
    actor: APIActor;
    received_at: string;
    type: Trigger;
  };
  errors: APIPipelineError[];
  trigger_parameters?: TriggerParametersAPI;
  policy_decision?: APIPolicyDecision;
}

export interface APIPolicyDecision {
  status?: string;
  enabled_rules?: string[];
  soft_failures?: PolicyFailure[];
  hard_failures?: PolicyFailure[];
}

export interface APIPipelinesResponse {
  items: Pick<APIPipeline, 'id'>[];
  next_page_token: string;
}

export interface APIPipelinesAndWorkflowsResponse {
  items: APIPipeline[];
  next_page_token: string;
}

export interface APIWorkflowJobsResponse {
  items: APIV2WorkflowJob[];
}

export interface APIJobRerunFailedTestsMetricsResponse {
  job_id: string;
  prev_job_id: string;
  total_test_count: number;
  failed_test_count: number;
  total_prev_job_duration: number;
}

export interface APIWorkflowRerunFailedTestsMetricsResponse {
  was_rerun_failed_tests: boolean;
  total_prev_jobs_duration: number;
  total_test_count: number;
  failed_test_count: number;
  rerun_jobs: APIJobRerunFailedTestsMetricsResponse[];
}

export interface APIV2Workflow {
  id: string;
  name: string;
  status: string;
  created_at: string;
  stopped_at?: string;
  pipeline_id: string;
  pipeline_number: number | null;
  project_slug: ProjectSlug;
  canceled_by?: string;
  tag?: string;
  rerun_metrics?: APIWorkflowRerunFailedTestsMetricsResponse;
}

export interface APIV2WorkflowJob {
  id: string;
  dependencies: string[];
  name: string;
  job_number?: number;
  status: string;
  started_at?: string;
  stopped_at?: string;
  type: string;
}

export interface APIStep {
  actions: APIAction[];
}

export interface APIStepOutput {
  message: string;
  sequence?: number;
  truncated: boolean;
}

export type APITestResponse = void | {
  tests: APITestResult[];
  exceptions: string[][] | null;
};

export interface APITestResult {
  classname?: string;
  file?: string;
  message?: string;
  name?: string;
  result?: string;
  run_time?: number;
  source?: string;
  source_type?: string;
}

export interface APIProjectV2 {
  slug: string;
  name: string;
  organization_name: string;
  organization_id: string;
}

export interface APIPullRequest {
  head_sha?: string;
  url: string;
}

export interface APIWorkflow {
  job_id: string;
  job_name: string;
  workflow_id: string;
  workflow_name: string;
}

export interface APICommit {
  commit: string;
  commit_url: string;
}

export interface APIJobMessage {
  type: string;
  reason: string;
  message: string;
}

export interface APIResourceClass {
  cpu: number | null;
  ram: number | null;
  class: string;
  name: string | null;
}

export interface APIJob {
  all_commit_details: APICommit[];
  all_commit_details_truncated: boolean;
  build_num: number;
  build_url: string;
  messages: APIJobMessage[];
  pull_requests: APIPullRequest[];
  start_time: string | null;
  status: string;
  steps: APIStep[];
  stop_time: string | null;
  queued_at?: string | null;
  usage_queued_at?: string | null;
  workflows?: APIWorkflow;
  user: APIUser;
  vcs_url: string;
  vcs_revision: string;
  why: string | null;
  branch: string;
  picard: {
    resource_class: APIResourceClass;
    executor: string;
  } | null;
  oss?: boolean;
  job_id?: string | null;
  retries: number[] | null;
  ssh_users: {}[];
  // for legacy jobs, this is where the job name comes from since there are no workflows
  job_name?: string | null;
  platform?: string | null;
  using_new_steps_api: boolean;
}

export interface APIWorkflowRerun {
  workflow_id: string;
}

export interface APILegacyJob {
  branch: string | undefined;
  build_num: number;
  job_name?: string;
  start_time: string | undefined;
  status: string;
  stop_time: string | undefined;
  subject: string;
  user: {
    avatar_url: string | undefined;
    name: string | undefined;
  };
  workflows?: { job_name: string | undefined };
}
export interface APIOrgJob {
  branch: string | undefined;
  build_num: number;
  job_name?: string;
  reponame: string;
  start_time: string | undefined;
  status: string;
  stop_time: string | undefined;
  subject: string;
  user: {
    avatar_url: string | undefined;
    name: string | undefined;
  };
  workflows?: { job_name: string | undefined };
}

export interface Actor {
  avatarUrl?: string;
  name?: string;
  vcsUrl: string;
}

export interface Config {
  compiled: string;
  source: string;
}

export interface PullRequest {
  headSHA?: string;
  url: string;
}

export enum JobMessageType {
  error = 'error',
  info = 'info',
  warning = 'warning',
  unknown = 'unknown',
  setup = 'setup',
}

export interface JobMessage {
  message: string;
  reason?: string;
  type: JobMessageType;
}

export interface Job {
  usingNewStepsAPI: boolean;
  actor: Actor;
  buildNumber: number;
  buildUrl: string;
  commits: Commit[];
  commitsTruncated: boolean;
  cacheKey: string;
  id: string;
  messages: JobMessage[];
  name?: string;
  parallelRuns: ParallelRun[];
  pipelinesEnabled: boolean;
  startedAt: string;
  queuedAt: string | null;
  usageQueuedAt: string | null;
  done: boolean;
  status: string;
  stoppedAt: string;
  workflow: Workflow;
  vcsUrl: string;
  vcsRevision: string;
  why: string;
  branch?: string;
  testSummary: () => Promise<TestSummary>;
  pipelineId: string | undefined;
  pipeline?: Pipeline | undefined;
  resourceCpu: number;
  resourceRam: number | undefined;
  resourceClass: string;
  resourceName: string | undefined;
  resourceExecutor: string;
  oss: boolean;
  retries: number[];
}

export interface LegacyJob {
  actor: Actor;
  buildNumber: number;
  buildUrl: string;
  commits: Commit[] | null;
  commitsTruncated: boolean;
  commitSubject: string;
  id: string;
  messages: JobMessage[];
  name: string | null;
  // when a job has no workflow, there could be a job_name key in the job api call
  legacyName: string | null;
  parallelRuns: ParallelRun[];
  startedAt: string;
  queuedAt: string | null;
  usageQueuedAt: string | null;
  done: boolean;
  status: string;
  stoppedAt: string;
  workflow: Workflow;
  vcsUrl: string;
  vcsRevision: string;
  why: string;
  branch: string | null;
  testSummary: () => Promise<TestSummary>;
  resourceCpu: number;
  resourceRam?: number;
  resourceClass: string;
  resourceName: string | undefined;
  resourceExecutor: string;
  platform: string | undefined;
  oss: boolean;
}
export interface OrgJob {
  actor: Actor;
  buildNumber: number;
  buildUrl: string;
  commits: Commit[] | null;
  commitsTruncated: boolean;
  commitSubject: string;
  id: string;
  messages: JobMessage[];
  name: string | null;
  repoName: string;
  username: string;

  // when a job has no workflow, there could be a job_name key in the job api call
  legacyName: string | null;
  parallelRuns: ParallelRun[];
  startedAt: string;
  queuedAt: string | null;
  usageQueuedAt: string | null;
  done: boolean;
  status: string;
  stoppedAt: string;
  workflow: Workflow;
  vcsUrl: string;
  vcsRevision: string;
  why: string;
  branch: string | null;
  testSummary: () => Promise<TestSummary>;
  resourceCpu: number;
  resourceRam: number | null;
  resourceClass: string;
  resourceName: string | null;
  resourceExecutor: string;
  platform: string | null;
  oss: boolean;
}

export interface TestSummaryException {
  output: string[];
}

export interface TestSummary {
  exceptions: TestSummaryException[];
  exceptionCount: number;
  failed: TestResult[];
  failedCount: number;
  totalCount: number;
  isSetup: boolean;
}

export interface TestResult {
  fileName?: string;
  className?: string;
  message?: string;
  name?: string;
  result?: string;
  runTime?: number;
  source?: string;
  sourceType?: string;
}

export interface Commit {
  vcsRevision: string;
  vcsUrl: string;
}

export interface Workflow {
  id: string;
  name: string;
  jobID?: string;
  pullRequests: PullRequest[];
}

export interface JobRerunFailedTestsMetrics {
  id: string;
  totalTestCount: number;
  failedTestCount: number;
  prevDuration: number;
}

export interface WorkflowRerunFailedTestsMetrics {
  wasRerunFailedTests: boolean;
  prevTestsDuration: number;
  totalTestCount: number;
  failedTestCount: number;
  jobs: JobRerunFailedTestsMetrics[];
}

export interface PipelineWorkflow {
  id: string;
  name: string;
  status: string | null;
  createdAt: string;
  stoppedAt?: string;
  pipelineId: string;
  pipelineNumber: string | undefined;
  projectSlug: ProjectSlug;
  systemActor: PipelineActor | undefined;
  tag?: string;
  rerunFailedTestsMetrics?: WorkflowRerunFailedTestsMetrics;
}

export type ApproverActor = {
  avatarURL: string;
  name: string;
};
export type APIServiceJobAction = {
  actor: ApproverActor;
  type: string;
};

export type JobActions = Array<APIServiceJobAction>;
export interface APIServiceWorkflowGraphJobs {
  actions: JobActions;
  buildNumber: number;
  id: string;
}

export interface ParallelRun {
  id: string;
  slug: string;
  status: string;
  steps: ParallelRunStep[];
}

export interface ParallelRunStep {
  id: string;
  slug: string;
  stepIndex: number;
  allocationId: string | undefined;
  insignificant: boolean;
  name: string;
  runtime: number;
  status: string;
  startedAt?: string;
  stoppedAt?: string;
  exitCode?: number;
  bashCommand?: string;
}

export interface ParallelRunStepOutput {
  messages: string[];
  sequence?: number;
  bashCommand?: string;
  truncated?: boolean;
}

export interface PipelineActor {
  avatarUrl?: string;
  name: string;
}

export interface PipelineCommit {
  subject?: string;
}

export interface APIPipelineError {
  type: string;
  message: string;
}

/**
 * Because graphql does not allow hyphens in its types, it's impossible for apollo to handle the original
 * `config-fetch` value of the PipelineMessageType. Because of that, in `src/graphql/shim/mappers`, we have to
 * transform `config-fetch` into `configFetch` to have apollo handle it.
 * GraphQL spec: https://spec.graphql.org/October2021/#sec-Names
 */
export type PipelineMessageType = 'config' | 'configFetch' | 'plan' | 'unknown';

export interface PipelineMessage {
  type: PipelineMessageType;
  messages: string[];
}

export interface TriggerParametersGitHubApp {
  branch?: string;
  commitSha?: string;
  commitTitle?: string;
  ref?: string;
  repoFullName?: string;
  repoUrl?: string;
  userUsername?: string;
}

export interface TriggerParameters {
  circleci: { triggerType?: string; actorId?: string };
  event: { title?: string; description?: string };
  githubApp?: TriggerParametersGitHubApp;
}

/**
 * @property {object} projectV2 - Used as part of decoupling prototype: contains project name & slug and org name
 */
export interface Pipeline {
  id: string;
  projectSlug: ProjectSlug;
  projectV2?: ProjectV2;
  state: string;
  createdAt: string;
  number: string;
  workflows: PipelineWorkflow[];
  branch: string | undefined;
  tag: string | undefined;
  commit: PipelineCommit | undefined;
  vcsRevision: string | undefined;
  actor: PipelineActor;
  vcsUrl: string | undefined;
  messages: PipelineMessage[];
  triggerType: Trigger;
  policyDecision?: PolicyDecision;
  triggerParameters: TriggerParameters;
}

/**
 * Represents a list of pipelines in response to a query, either partial or
 * complete. If partial, nextPageToken will be a string representing a cursor
 * pagination token.
 */
export interface Pipelines {
  items: Pipeline[];
  nextPageToken: string;
}

export interface WorkflowJob {
  id: string;
  dependencies: string[];
  name: string;
  buildNumber?: number;
  status: string;
  startedAt?: string;
  stoppedAt?: string;
  type: string;
}

export interface RerunWithSshJob {
  buildNumber: number | undefined;
  newWorkflowId: string;
}

export interface APIProject {
  vcs_type: VCSType;
  username: string;
  name: string;
  following: boolean;
  owner: {
    avatar_url: string;
  };
}

/**
 * A single item in the response from a call to /api/v1/projects?shallow=true
 */
export interface APIV1Project {
  vcs_type: VCSType;
  username: string;
  reponame: string;
  default_branch: string;
  branches: {
    [name: string]: {
      latest_completed_workflows?: {
        [key: string]: {
          status: string;
          created_at: string;
        };
      };
    };
  };
}

export interface ProjectInfo {
  exists: boolean;
  analyticsId: string;
  hasFollowers: boolean;
  permissions: {
    triggerBuilds: boolean;
    write: boolean;
    approveJobs: boolean;
  };
}

export interface APIProjectInfo {
  exists?: boolean;
  analytics_id: string;
  has_followers: boolean;
  scopes: string[];
}
export interface APIArtifact {
  node_index: number;
  path: string;
  pretty_path: string;
  url: string;
}

export type APIArtifacts = APIArtifact[];

export interface Artifact {
  url: string;
  path: string;
  parallelRunIndex: number;
}

export interface APIProjectBranches {
  items: APIBranch[];
  default: string | null;
}

export interface APIBranch {
  name: string;
}

export interface ProjectBranches {
  items: Branch[];
  default: string | null;
}

export interface Branch {
  name: string;
  id: string;
}

export type Artifacts = Artifact[];

export type SshJob = Pick<
  Job,
  'id' | 'buildNumber' | 'startedAt' | 'stoppedAt' | 'status'
>;

export interface LatestConfig {
  content: string;
  path: string;
  sha: string;
}

export interface PolicyDecision {
  status?: string;
  enabledRules?: string[];
  softFailures?: PolicyFailure[];
  hardFailures?: PolicyFailure[];
}

export interface PolicyFailure {
  rule?: string;
  reason?: string;
}
