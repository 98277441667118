import { client } from '@circleci/web-ui-data';

import { VCSType } from '~/graphql/shim/types';
import { shortVCSType } from '~/utils/job';

type Fetch = typeof fetch;

const setupProject = (
  fetch: Fetch,
  apiKey: string | undefined,
  vcsType: VCSType,
  org: string,
  project: string,
  config: string,
  branch?: string,
  message?: string,
): Promise<void> => {
  const pathname = `/api/v1.1/project/${shortVCSType(
    vcsType,
  )}/${org}/${project}/setup`;

  const body: { config: string; branch?: string; message?: string } = {
    config,
  };

  if (branch) {
    body.branch = branch;
  }

  if (message) {
    body.message = message;
  }

  return client.apiMutator({ apiKey, fetch, pathname, body });
};

export default setupProject;
