import { APIWorkflowRerun } from '~/graphql/shim/types';
import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

const rerunJobWithSsh = async (
  fetch: Fetch,
  apiKey: string,
  workflowId: string,
  jobId: string,
): Promise<APIWorkflowRerun> => {
  const body: Record<string, any> = {
    jobs: [jobId],
    enable_ssh: true,
  };

  const pathname = `/api/v2/workflow/${workflowId}/rerun`;
  const response = await client.apiMutator({
    apiKey,
    fetch,
    body: body,
    pathname,
    callerName: 'rerunJobWithSsh',
  });

  return await response.json();
};
export default rerunJobWithSsh;
