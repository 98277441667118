const typeDefs = `
enum Week {
  MON
  TUE
  WED
  THU
  FRI
  SAT
  SUN
}

enum Month {
  JAN
  FEB
  MAR
  APR
  MAY
  JUN
  JUL
  AUG
  SEP
  OCT
  NOV
  DEC
}

enum ParameterType {
  string
  boolean
  integer
}

enum BranchOrTagType {
  branch
  tag
}

type Parameter {
  name: String!
  type: ParameterType!
  value: String!
}

type TriggerActor {
  id: ID!
  name: String
  login: String!
}

type Timetable {
  days_of_week: [Week]!
  hours_of_day: [Int]!
  per_hour: Int!
  days_of_month: [Int]!
  months: [Month]!
}

type BranchOrTag {
  type: BranchOrTagType!
  value: String!
}

type ScheduledTrigger {
  description: String
  name: String!
  id: ID!
  created_at: String!
  branchOrTag: BranchOrTag!
  # parameters is set to an object with keys: name, value, type
  # as graphql does not support dynamic fields
  parameters: [Parameter]!
  actor: TriggerActor!
  timetable: Timetable!
  source: String
}

type ScheduledTriggers {
  items: [ScheduledTrigger]!
  nextPageToken: String
}

type ResponseMessage {
  message: String!
}

extend type Query {
  scheduledTrigger(
    id: ID!
  ): ScheduledTrigger!
  scheduledTriggers(
    vcsType: String!
    orgName: String!
    projectName: String!
    pageToken: String
  ): ScheduledTriggers!
}

input InputParameter {
  name: String!
  type: ParameterType!
  value: String!
}

enum InputTriggerActor {
  current
  system
  previous
}

input InputBranchOrTag {
  type: BranchOrTagType!
  value: String!
}

input CreateTriggerInput {
  name: String!
  description: String
  actor: InputTriggerActor!
  branchOrTag: InputBranchOrTag!
  parameters: [InputParameter]!
  days_of_week: [Week]
  hours_of_day: [Int]!
  per_hour: Int!
  days_of_month: [Int]
  months: [Month]
}

input UpdateTriggerInput {
  name: String
  description: String
  actor: InputTriggerActor
  branchOrTag: InputBranchOrTag
  parameters: [InputParameter]
  days_of_week: [Week]
  hours_of_day: [Int]
  per_hour: Int
  days_of_month: [Int]
  months: [Month]
}

extend type Mutation {
  createScheduledTrigger(
    vcsType: String!
    orgName: String!
    projectName: String!
    schedule: CreateTriggerInput!
  ): ScheduledTrigger!

  deleteScheduledTrigger(
    id: ID!
  ): ResponseMessage

  updateScheduledTrigger(
    id: ID!
    schedule: UpdateTriggerInput!
  ): ScheduledTrigger!
}
`;

export default typeDefs;
