import { Router } from 'next/router';
import * as React from 'react';

/**
 * Provide class slack link unfurling tailored to the invite scenario, when the ?invite=true query parameter is provided
 * https://api.slack.com/reference/messaging/link-unfurling#classic_unfurl
 */
function renderInviteSlackUnfurl(router: Router): JSX.Element {
  if (router?.query?.invite === 'true') {
    return (
      <>
        <meta
          property="og:title"
          content="You've been invited to join CircleCI!"
        />
        <meta
          property="og:description"
          content="Click here to join your teammate and start building faster and smarter on our CI/CD platform."
        />
        <meta
          property="og:image"
          content="https://production-cci-com.imgix.net/blog/media/2020.08.17_New%20UI.jpg?ixlib=rb-3.2.1&auto=format&fit=max&q=60&ch=DPR%2CWidth%2CViewport-Width%2CSave-Data&w=1035"
        />
      </>
    );
  }

  return null as any;
}

export { renderInviteSlackUnfurl };
