import * as R from 'ramda';

import { APIProjectSettings } from '~/project-settings/graphql/shim/fetchers/projectSettings';
import { APIUserOrganization } from '~/project-settings/graphql/shim/fetchers/userOrganizations';

interface Props {
  vcsType: string;
  orgName: string;
}

const findOrg = ({ vcsType, orgName }: Props, orgs: APIUserOrganization[]) =>
  R.find(R.whereEq({ vcs_type: vcsType, login: orgName }), orgs);

const isAdmin = (props: Props, orgs: APIUserOrganization[]) => {
  const org = findOrg(props, orgs);
  return R.propOr<boolean, APIUserOrganization, boolean>(
    false,
    'admin',
    org as APIUserOrganization,
  );
};

const mapProjectSettings = (
  props: Props,
  {
    following,
    aws,
    scopes = [],
  }: Pick<APIProjectSettings, 'following' | 'aws' | 'scopes'>,
  orgs: APIUserOrganization[],
) => ({
  admin: isAdmin(props, orgs),
  following: !!following,
  aws: aws ? aws.keypair : undefined,
  canStopBuilds: scopes.includes('all'), // TODO: Revisit when scopes are to be deprecated from endpoint
});

export default mapProjectSettings;
