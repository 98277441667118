import TPusher from 'pusher-js';

import { createPusherWith } from '~/utils/pusher';
import global from '~/utils/global';

const dependencies: { pusherSingleton: TPusher | null } = {
  pusherSingleton: null,
};

export const initPusher = () => {
  const pusherAppKey = global.circleci?.config?.pusherKey;
  dependencies.pusherSingleton = createPusherWith(pusherAppKey)();
};

export const overridePusher = (pusher: any) => {
  dependencies.pusherSingleton = pusher;
};

const getPusher = () => dependencies.pusherSingleton;
export default getPusher;
