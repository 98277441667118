import { client } from '@circleci/web-ui-data';
import { ProjectBranches, VCSType } from '~/graphql/shim/types';

type Fetch = typeof fetch;

interface Props {
  apiKey: string;
  fetch: Fetch;
  vcsType: VCSType;
  username: string;
  project: string;
}

const fetchProjectBranches = async ({
  apiKey,
  fetch,
  vcsType,
  username,
  project,
}: Props) => {
  const pathname = `/api/private/project/${vcsType}/${username}/${project}/branches`;

  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    callerName: 'fetchProjectBranches',
  });
  return (await response.json()) as ProjectBranches;
};

export default fetchProjectBranches;
