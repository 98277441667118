import { mapScheduledTrigger } from '~/project-settings/packages/triggers/graphql/mappers/mapScheduledTrigger';
import { mapScheduledTriggerCreate } from '~/project-settings/packages/triggers/graphql/mappers/mapScheduledTriggerCreate';
import { updateScheduledTrigger as mutateUpdateScheduledTrigger } from '~/project-settings/packages/triggers/graphql/mutators/updateScheduledTrigger';
import { Deps } from '~/project-settings/packages/triggers/graphql/resolvers/types';
import { MutationToUpdateScheduledTriggerArgs } from '~/project-settings/packages/triggers/graphql/types';

export const updateScheduledTrigger = async (
  _root: any,
  props: MutationToUpdateScheduledTriggerArgs,
  deps: Deps,
) => {
  const apiSchedule = mapScheduledTriggerCreate(props.schedule);

  const result = await mutateUpdateScheduledTrigger({
    apiKey: deps.apiKey,
    fetch: deps.fetch,
    updatedTrigger: apiSchedule,
    scheduledTriggerId: props.id,
  });
  return mapScheduledTrigger(result);
};
