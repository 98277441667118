import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
}

const stopBuilding = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
}: Props) => {
  const pathname = `/api/v1.1/project/${vcsType}/${orgName}/${projectName}/enable`;
  await client.apiMutator({ apiKey, fetch, pathname, method: 'DELETE' });
};
export default stopBuilding;
