import { client } from '@circleci/web-ui-data';
import * as R from 'ramda';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
  token: string;
}

type PathProps = Pick<Props, 'vcsType' | 'orgName' | 'projectName' | 'token'>;

const pathname = (props: PathProps) => {
  const { vcsType, orgName, projectName, token } = R.mapObjIndexed(
    encodeURIComponent,
    props,
  );
  return `/api/v1.1/project/${vcsType}/${orgName}/${projectName}/token/${token}`;
};

const mutateDeleteApiToken = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
  token,
}: Props) => {
  await client.apiMutator({
    apiKey,
    fetch,
    pathname: pathname({ vcsType, orgName, projectName, token }),
    method: 'DELETE',
  });
};
export default mutateDeleteApiToken;
