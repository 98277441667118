import * as React from 'react';
import { hotjar } from 'react-hotjar';

import MeContext from '~/contexts/MeContext';
import global from '~/utils/global';

const HotJarProvider = ({ children }: any) => {
  const { privacyOptOut } = React.useContext(MeContext);
  const { hotjarId } = global.circleci.config;
  if (!privacyOptOut && hotjarId) {
    hotjar.initialize(hotjarId, 6);
  }

  return <>{children}</>;
};

export default HotJarProvider;
