import * as React from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';

import errorLink from '~/graphql/links/errorLink';
import schemaLink from '~/graphql/links/schemaLink';

const link = ApolloLink.from([errorLink, schemaLink]);

export const makeClient = () =>
  new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Job: {
          keyFields: ['cacheKey'],
        },
      },
    }),
    link,
    connectToDevTools: process.env.NODE_ENV === 'development',
  });

export const client = makeClient();

export const Provider = ({ children, ...props }: any) => (
  <ApolloProvider client={client} {...props}>
    {children}
  </ApolloProvider>
);
