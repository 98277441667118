import { client } from '@circleci/web-ui-data';

import { WebhookFeatures } from '~/project-settings/graphql/types/webhook';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  scopeId: string;
  scopeType: string;
}

const fetchWebhookFeatures = async ({
  apiKey,
  fetch,
  scopeId,
  scopeType,
}: Props): Promise<WebhookFeatures> => {
  const pathname = `/api/private/webhook-features`;
  const query = {
    'scope-id': scopeId,
    'scope-type': scopeType,
  };
  const response = await client.apiFetcher({ apiKey, fetch, pathname, query });
  return await response.json();
};

export default fetchWebhookFeatures;
