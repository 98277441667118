import * as R from 'ramda';
import * as url from 'url';

import {
  JobActions,
  VCSType,
  APIServiceJobAction,
  ApproverActor,
} from '~/graphql/shim/types';
import { JobStatus, ShortVCS } from '~/types/types';

interface FilterJobUrlProps {
  buildNumber: number;
  parallelRunSlug: string;
  project: string;
  username: string;
  vcsType: string;
  filterBy: string;
  pipelineNumber: string | null;
  workflowId: string | null;
  shouldIncludeParallelRunSlug?: boolean;
}

const queryToString = (query: {}): string =>
  new URLSearchParams(query).toString();

const buildHref = ({
  shouldIncludeParallelRunSlug = true,
  pipelineNumber,
  workflowId,
}: FilterJobUrlProps) => {
  const prefix =
    R.isNil(pipelineNumber) || R.isNil(workflowId)
      ? `/jobs/[vcsType]/[username]/[project]/[buildNumber]`
      : `/pipelines/[vcsType]/[username]/[project]/[pipelineNumber]/workflows/[workflowId]/jobs/[buildNumber]`;
  if (shouldIncludeParallelRunSlug) {
    return `${prefix}/parallel-runs/[parallelRunSlug]`;
  }
  return prefix;
};

const buildAsPathname = ({
  buildNumber,
  shouldIncludeParallelRunSlug = true,
  parallelRunSlug,
  project,
  username,
  vcsType,
  pipelineNumber,
  workflowId,
}: FilterJobUrlProps) => {
  const prefix =
    R.isNil(pipelineNumber) || R.isNil(workflowId)
      ? `/jobs/${vcsType}/${username}/${project}/${buildNumber}`
      : `/pipelines/${vcsType}/${username}/${project}/${pipelineNumber}/workflows/${workflowId}/jobs/${buildNumber}`;

  if (shouldIncludeParallelRunSlug) {
    return `${prefix}/parallel-runs/${parallelRunSlug}`;
  }
  return prefix;
};

export const filterJobUrl = (
  props: FilterJobUrlProps,
): { href: string; as: string } => {
  return {
    href: url.format({
      pathname: buildHref(props),
      search: queryToString({
        filterBy: props.filterBy,
      }),
    }),
    as: url.format({
      pathname: buildAsPathname(props),
      search: queryToString({
        filterBy: props.filterBy,
      }),
    }),
  };
};

const vcsTypeMap = {
  bitbucket: 'bb',
  github: 'gh',
  circleci: 'circleci',
};
type VCSTypeMap = typeof vcsTypeMap;

export const shortVCSType = (longVCSType: VCSType) =>
  R.propOr<ShortVCS | null, VCSTypeMap, ShortVCS>(
    null,
    longVCSType,
    vcsTypeMap,
  );

export const optOutJobUrl = (buildUrl: string): string => {
  const jobUrl = new URL(buildUrl);

  jobUrl.search = `pipelines-ui-opt-out`;
  return jobUrl.href;
};

export const approvalActor = (
  jobActions: JobActions = [],
): ApproverActor | undefined => {
  const approvalJobActions = jobActions.filter(
    (action: APIServiceJobAction) => action.type === 'APPROVE',
  );

  return approvalJobActions?.length == 0
    ? undefined
    : approvalJobActions[0]?.actor;
};

export function isJobCancelled(jobStatus: JobStatus): boolean {
  return jobStatus === 'CANCELLED' || jobStatus === 'CANCELED';
}

export function isJobTerminalState(jobStatus: JobStatus): boolean {
  return (
    jobStatus === 'CANCELLED' ||
    jobStatus === 'CANCELED' ||
    jobStatus === 'SUCCESS' ||
    jobStatus === 'FAILED'
  );
}
