import { createScheduledTrigger } from '~/project-settings/packages/triggers/graphql/resolvers/createScheduledTrigger';
import { deleteScheduledTrigger } from '~/project-settings/packages/triggers/graphql/resolvers/deleteScheduledTrigger';
import { updateScheduledTrigger } from '~/project-settings/packages/triggers/graphql/resolvers/updateScheduledTrigger';
import { Resolver } from '~/project-settings/packages/triggers/graphql/types';

import { getScheduledTrigger } from './getScheduledTrigger';
import { getScheduledTriggersList } from './getScheduledTriggersList';

const resolvers: Resolver = {
  Query: {
    scheduledTrigger: getScheduledTrigger,
    scheduledTriggers: getScheduledTriggersList,
  },
  Mutation: {
    deleteScheduledTrigger: deleteScheduledTrigger,
    updateScheduledTrigger: updateScheduledTrigger,
    createScheduledTrigger: createScheduledTrigger,
  },
};

export default resolvers;
