import { APICollaborations, Collaborations } from '~/graphql/shim/types';

const mapAPICollaborationsToGraphQL = (
  collaborations: APICollaborations,
): Collaborations => {
  const superAdmin = collaborations.some((org) => org.name === 'circleci');

  return collaborations.map((collaboration) => ({
    id: collaboration.id,
    name: collaboration.name,
    avatarUrl: collaboration.avatar_url,
    vcsType: collaboration.vcs_type,
    superAdmin: superAdmin,
    slug: collaboration.slug,
  }));
};

export default mapAPICollaborationsToGraphQL;
