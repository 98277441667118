import { client } from '@circleci/web-ui-data';
import { VCSType } from '~/graphql/shim/types';
import { APIConfigRecommendationsResp } from '~/insights-experiments/graphql/shim/types';

export interface ConfigRecommendationsArgs {
  fetch: any;
  apiKey: string;
  vcsType: VCSType;
  orgName: string;
  projectName: string;
  workflowExecutionId: string;
}
const fetchConfigRecommendations = async ({
  fetch,
  apiKey,
  vcsType,
  orgName,
  projectName,
  workflowExecutionId,
}: ConfigRecommendationsArgs): Promise<APIConfigRecommendationsResp> => {
  const pathname = `api/v2/insights/${vcsType}/${orgName}/${projectName}/config/recommendations`;
  const query = {
    ...(workflowExecutionId && {
      'workflow-execution-id': workflowExecutionId,
    }),
  };

  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    query,
  });

  return await response.json();
};

export default fetchConfigRecommendations;
