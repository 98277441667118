import { onError } from '@apollo/client/link/error';
import ExceptionLogger from '~/utils/exceptionLogger';
import {
  isNetworkError,
  graphQLErrorIsNetworkError,
  isForbidden,
  isUnauthorized,
  isNotFoundError,
} from '@circleci/web-ui-data/build/client/errors';
import { GraphQLError } from 'graphql';
import { ApolloError } from '@apollo/client';

function isPermissionDenied(
  error: GraphQLError | ApolloError | Error | undefined,
): boolean {
  if (error && 'graphQLErrors' in error) {
    return error.graphQLErrors.some(
      (e: GraphQLError) =>
        e.extensions != undefined && e.extensions.type === 'PERMISSION_DENIED',
    );
  }

  if (error && 'extensions' in error && error.extensions != undefined) {
    return error.extensions.type === 'PERMISSION_DENIED';
  }

  return false;
}
const link = onError(({ graphQLErrors, networkError, operation }) => {
  if (networkError) {
    return;
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (graphQLErrorIsNetworkError(error) || isNetworkError(error)) {
        return;
      }

      if (
        isUnauthorized(error) ||
        isUnauthorized(error.originalError as Error) ||
        isForbidden(error) ||
        isForbidden(error.originalError as Error) ||
        isNotFoundError(error) ||
        isNotFoundError(error.originalError as Error) ||
        isPermissionDenied(error) ||
        isPermissionDenied(error.originalError as Error)
      ) {
        return;
      }
      const { message, locations, path } = error;
      ExceptionLogger.error(error, message, {
        locations,
        path,
        operationName: operation.operationName,
      });
    });
  }
});

export default link;
