import * as React from 'react';

import { Page } from '@circleci/analytics';
import { ErrorPage } from '@circleci/compass';
import Router from 'next/router';

import global from '~/project-settings/utils/global';

export default ({
  onGoBack = () => Router.back(),
}: {
  onGoBack?: () => void;
}) => {
  return (
    <Page
      orgSlug={null}
      pageType={'inner'}
      pageName={'projectSettingsNotFoundError'}
      teamName={'soc'}
    >
      <ErrorPage
        onActionClick={onGoBack}
        isEnterprise={global.circleci.config.isEnterprise}
        fgImageUrl="/settings/project/background-images/404-door.png"
        bgImageUrl="/settings/project/background-images/stars.jpg"
      />
    </Page>
  );
};
