import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

const rerunWorkflow = async (
  fetch: Fetch,
  apiKey: string,
  workflowId: string,
  from: 'FAILED' | 'BEGINNING',
): Promise<void> => {
  const preFilteredBody: Record<string, boolean | undefined> = {
    from_failed: from === 'FAILED',
  };

  const filteredBody = Object.fromEntries(
    Object.entries(preFilteredBody).filter(([_, v]) => v != undefined),
  );

  const pathname = `/api/v2/workflow/${workflowId}/rerun`;
  await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    body: filteredBody,
    callerName: 'rerunWorkflow',
  });
};
export default rerunWorkflow;
