import { APIPipeline, Pipeline } from '~/graphql/shim/types';
import pipelineMessages from '~/graphql/shim/mappers/pipelineMessages';
import convertVcsType from '~/utils/convertVcsType';
import { VCSData } from '~/types/types';
import { getVCSData } from '~/utils/vcs';
import { getActorData } from '~/utils/decoupling';
import { ProjectSlug } from '@circleci/web-ui-data/build/types/types';

type PipelineNoWorkflows = Omit<Pipeline, 'workflows'>;

const mapAPIPipelineToGraphQL = ({
  id,
  project_slug,
  state,
  created_at,
  number,
  vcs,
  trigger,
  errors,
  trigger_parameters,
  policy_decision,
}: APIPipeline): PipelineNoWorkflows => {
  const vcsData: VCSData = getVCSData(vcs, trigger_parameters || {});

  return {
    id,
    projectSlug: convertVcsType(project_slug) || ('' as ProjectSlug),
    state,
    createdAt: created_at,
    number: `${number}`,
    branch: vcsData.branch,
    tag: vcsData.tag,
    commit: vcsData.commit,
    vcsRevision: vcsData.revision,
    vcsUrl: vcsData.origin_repository_url,
    actor: getActorData(trigger?.actor, trigger_parameters || {}),
    messages: pipelineMessages(errors),
    triggerType: trigger.type,
    policyDecision: {
      status: policy_decision?.status,
      enabledRules: policy_decision?.enabled_rules,
      softFailures: policy_decision?.soft_failures,
    },
    triggerParameters: {
      circleci: {
        triggerType: trigger_parameters?.circleci?.trigger_type,
      },
      event: {
        title: trigger_parameters?.event?.title,
        description: trigger_parameters?.event?.description,
      },
      githubApp: {
        branch: trigger_parameters?.github_app?.branch,
        commitSha: trigger_parameters?.github_app?.commit_sha,
        commitTitle: trigger_parameters?.github_app?.commit_title,
        ref: trigger_parameters?.github_app?.ref,
        repoFullName: trigger_parameters?.github_app?.repo_full_name,
        repoUrl: trigger_parameters?.github_app?.repo_url,
        userUsername: trigger_parameters?.github_app?.user_username,
      },
    },
  };
};

export default mapAPIPipelineToGraphQL;
