import fetchSalientTrends, {
  SalientTrendArgs,
} from '~/insights-experiments/graphql/shim/fetchers/mostSalientTrend';
import fetchConfigRecommendations, {
  ConfigRecommendationsArgs,
} from '~/insights-experiments/graphql/shim/fetchers/configRecommendations';

interface Deps {
  apiKey: string;
  fetch: typeof window.fetch;
}

const resolvers = {
  Query: {
    salientTrends: async (
      _root: any,
      {
        vcsType,
        orgName,
        projectName,
        workflowName,
        branch,
        allBranches,
      }: SalientTrendArgs,
      { fetch, apiKey }: Deps,
    ) => {
      return await fetchSalientTrends({
        fetch,
        apiKey,
        vcsType,
        orgName,
        projectName,
        workflowName,
        branch,
        allBranches,
      });
    },
    configRecommendations: async (
      _root: any,
      {
        vcsType,
        orgName,
        projectName,
        workflowExecutionId,
      }: ConfigRecommendationsArgs,
      { fetch, apiKey }: Deps,
    ) => {
      const configRecommendations = await fetchConfigRecommendations({
        fetch,
        apiKey,
        vcsType,
        orgName,
        projectName,
        workflowExecutionId,
      });

      return {
        optimizationType: configRecommendations?.optimization_type,
        optimizationCategory: configRecommendations?.optimization_category,
        links: configRecommendations?.links,
        source: configRecommendations?.source,
        details: configRecommendations?.details,
        trigger: configRecommendations?.trigger,
        headline: configRecommendations?.headline,
      };
    },
  },
};

export default resolvers;
