import { useState } from 'react';

const useOpenClose = ({ initialState = false } = {}) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const toggle = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  return { isOpen, toggle, close, open };
};

export default useOpenClose;
