import { gql } from '@apollo/client';

const typeDefs = gql`
  type Follower {
    login: String
    avatar_url: String
    github_id: Int
  }

  type ProcessedConfig {
    errors: [ConfigError!]!
    valid: Boolean!
  }

  type ConfigError {
    message: String!
  }

  input StringKeyVal {
    key: String!
    val: String!
  }

  type Repo {
    vcsType: String!
    org: String!
    name: String!
    avatarUrl: String!
    following: Boolean!
    hasFollowers: Boolean!
    admin: Boolean!
    isNew: Boolean!
    language: String
  }

  type UserRole {
    role: String!
  }

  extend type Query {
    buildConfig(
      configYaml: String!
      pipelineValues: [StringKeyVal!]
    ): ProcessedConfig!
    gitHubRepos(page: Int!): [Repo!]!
    userRole: UserRole!
  }

  extend type Mutation {
    updateConfig(
      vcsType: String!
      org: String!
      name: String!
      config: String!
      branchName: String!
      commitMessage: String!
      commitSha: String!
    ): Boolean
    setupProject(
      vcsType: String!
      org: String!
      name: String!
      config: String!
      branch: String
      message: String
    ): Boolean
    validateConfig(
      configYaml: String!
      pipelineValues: [StringKeyVal!]
    ): ProcessedConfig!
  }
`;

export default typeDefs;
