import * as React from 'react';

import { CircleLogoIcon } from '@circleci/compass';

import LoadingIcon from '~/project-settings/components/shared/LoadingIcon';
import styled from '~/styled';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-grow: 1;
`;

// based on discussions around this, this isn't a heading but rather a snowflake span.
// details on the discussion here: https://circleci.slack.com/archives/C01NAUSQT4Z/p1629405022175100
const Text = styled.span(
  ({ theme }) => `
  margin: 0;
  padding: 0;
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.legacy.fontSizes[5]}px;
`,
);

const Loading = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > :not(:last-child) {
    margin-right: ${theme.legacy.space[2]}px;
  }
`,
);

const IconWrap = styled.div(
  ({ theme }) => `
  border-left: 1px solid #d4d4d4;

  > span {
    margin-left: ${theme.legacy.space[1]}px;
  }
`,
);

const LogoWrap = styled.div(
  ({ theme }) => `
    width: ${theme.legacy.space[6]}px;
    height: ${theme.legacy.space[6]}px;
    color: ${theme.legacy.colors.neutral900};
  `,
);

export default () => (
  <Wrap>
    <Loading>
      <LogoWrap>
        <CircleLogoIcon />
      </LogoWrap>
      <Text>Loading</Text>
      <IconWrap>
        <LoadingIcon />
      </IconWrap>
    </Loading>
  </Wrap>
);
