import { VCSType, APIPipelinesResponse } from '~/graphql/shim/types';
import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

interface Props {
  fetch: Fetch;
  apiKey: string;
  vcsType: VCSType;
  username: string;
  project: string;
  branch?: string;
  filter?: string;
  pageToken?: string;
}

const pipelinesPath = ({
  vcsType,
  username,
  project,
  filter,
}: Pick<Props, 'vcsType' | 'username' | 'project' | 'filter'>) => {
  if (filter === 'mine') {
    return `/api/v2/project/${vcsType}/${username}/${project}/pipeline/mine`;
  }
  return `/api/v2/project/${vcsType}/${username}/${project}/pipeline`;
};

const pipelinesQuery = ({
  branch,
  pageToken,
}: Pick<Props, 'branch' | 'pageToken'>) => {
  if (pageToken) return { 'page-token': pageToken };
  if (branch) return { branch };
  return {};
};

export const fetchPipelines = async ({
  fetch,
  apiKey,
  vcsType,
  username,
  project,
  branch,
  filter,
  pageToken,
}: Props): Promise<APIPipelinesResponse> => {
  const pathname = pipelinesPath({
    vcsType,
    username,
    project,
    filter,
  });
  const query = pipelinesQuery({ branch, pageToken });
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    query: query as any,
    callerName: 'pipelines',
  });
  return await response.json();
};
