import { Errors } from '~/project-settings/graphql/types';

const createSshKey = (response: any): Errors => {
  const hostnameErrorMessage =
    "the hostname isn't valid. Try checking this Wikipedia article for hostname restrictions: http://en.wikipedia.org/wiki/Hostname#Restrictions_on_valid_host_names";
  if (response.message && response.message === hostnameErrorMessage) {
    return {
      errors: [
        { name: 'hostname', type: 'server', message: hostnameErrorMessage },
      ],
    };
  }
  if (response.message) {
    return {
      errors: [
        { name: 'privateKey', type: 'server', message: response.message },
      ],
    };
  }
  return { errors: [] };
};

export default createSshKey;
