import styled from '~/styled';
import * as React from 'react';

import LoadingIndicator from '~/components/shared-components/LoadingIndicator';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-grow: 1;
`;

const PageLoading: React.FC = () => (
  <Wrap>
    <LoadingIndicator />
  </Wrap>
);

export default PageLoading;
