import { client } from '@circleci/web-ui-data';

import {
  Month,
  TriggerActor,
  Week,
} from '~/project-settings/packages/triggers/graphql/types';
import { ReservedParameters } from '~/project-settings/packages/triggers/utils/constants';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  id: string;
}

export type APIParameterValue = string | number | boolean;

export type APIParameters = {
  [key in ReservedParameters]?: APIParameterValue;
} & {
  [key: string]: APIParameterValue;
};

export type APITimetable = {
  'days-of-week': Array<Week | null>;
  'hours-of-day': Array<number | null>;
  'per-hour': number;
  'days-of-month': Array<number | null>;
  months: Array<Month | null>;
};

export type APIScheduledTrigger = {
  description?: string;
  name: string;
  id: string;
  'created-at': string;
  'updated-at'?: string;
  parameters: APIParameters;
  actor: TriggerActor;
  timetable: APITimetable;
};

export const fetchScheduledTrigger = async ({
  apiKey,
  fetch,
  id,
}: Props): Promise<APIScheduledTrigger> => {
  const pathname = `/api/v2/schedule/${id}`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};
