import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
}

export interface APIProjectPrivacyStatus {
  oss: boolean;
}

const fetchProjectPrivacyStatus = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
}: Props): Promise<APIProjectPrivacyStatus> => {
  const pathname = `/api/v1.1/project/${vcsType}/${orgName}/${projectName}/info`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};

export default fetchProjectPrivacyStatus;
