import * as R from 'ramda';
import fetchAPIAction from '~/graphql/shim/fetchers/action';
import fetchAPIConfig from '~/graphql/shim/fetchers/config';
import fetchAPIJob from '~/graphql/shim/fetchers/job';
import fetchAPIPipelineWorkflows from '~/graphql/shim/fetchers/pipelineWorkflows';
import fetchAPIPipeline from '~/graphql/shim/fetchers/pipeline';
import { fetchPipelines as fetchAPIPipelines } from '~/graphql/shim/fetchers/pipelines';
import fetchAPICrossProjectPipelines from '~/graphql/shim/fetchers/crossProjectPipelines';
import fetchAPIProjectBranches from '~/graphql/shim/fetchers/projectBranches';
import fetchAPITestResults from '~/graphql/shim/fetchers/testResults';
import fetchAPIArtifacts from '~/graphql/shim/fetchers/artifacts';
import fetchAPIWorkflowJobs from '~/graphql/shim/fetchers/workflowJobs';
import fetchStepOutput from '~/graphql/shim/fetchers/stepOutput';
import fetchAPIProjectInfo from '~/graphql/shim/fetchers/projectInfo';
import fetchLatestConfig from '~/graphql/shim/fetchers/latestConfig';
import mapAPIJobToGraphQL from '~/graphql/shim/mappers/job';
import mapAPIProjectInfoToGraphQL from '~/graphql/shim/mappers/projectInfo';
import mapAPIProjectsToProjectBranches from '~/graphql/shim/mappers/projectBranches';
import mapAPIStepOutputToGraphQL from '~/graphql/shim/mappers/stepOutput';
import mapAPITestResultsToGraphQL from '~/graphql/shim/mappers/testResults';
import mapAPIArtifactsToGraphQL from '~/graphql/shim/mappers/artifacts';
import mapAPILegacyJobToGraphQL from '~/graphql/shim/mappers/legacyJob';
import mapAPILegacyJobsToGraphQL from '~/graphql/shim/mappers/legacyJobs';
import mapAPIOrgJobsToGraphQL from '~/graphql/shim/mappers/orgJobs';
import mapAPIPipelineToGraphQL from '~/graphql/shim/mappers/pipeline';
import mapAPIPipelineToPipilineWorkflowsGraphQL from '~/graphql/shim/mappers/pipelineWorkflows';
import fetchAPIPipelineByNumber from '~/graphql/shim/fetchers/pipelineByNumber';
import { mapAPIPipelinesToGraphQL } from '~/graphql/shim/mappers/pipelines';
import mapAPIWorkflowToWorkflow from '~/graphql/shim/mappers/workflow';
import mapAPICollaborationsToGraphQL from '~/graphql/shim/mappers/collaborations';
import mapAPIWorkflowJobsResponseToGraphQL from '~/graphql/shim/mappers/workflowJobs';
import { mapWorkflowJobsToNewRerunBuildNumber } from '~/graphql/shim/mappers/rerunWithSshJob';
import mapApiJobToSshJob from '~/graphql/shim/mappers/sshJob';

import fetchAPILegacyJobs from '~/graphql/shim/fetchers/legacyJobs';
import fetchAPIOrgJobs from '~/graphql/shim/fetchers/orgJobs';
import fetchAPIWorkflow from '~/graphql/shim/fetchers/workflow';
import fetchAPICollaborations from '~/graphql/shim/fetchers/collaborations';

import apiServiceWorkflowGraphJobs from '~/graphql/shim/fetchers/apiServiceWorkflowGraphJobs';

import cancelWorkflow from '~/graphql/shim/mutators/cancelWorkflow';
import rerunWorkflow from '~/graphql/shim/mutators/rerunWorkflow';
import cancelJob from '~/graphql/shim/mutators/cancelJob';
import followProject from '~/graphql/shim/mutators/followProject';
import unfollowProject from '~/graphql/shim/mutators/unfollowProject';
import triggerPipeline from '~/graphql/shim/mutators/triggerPipeline';
import { fetchAPIPipelinesAndWorkflows } from '~/graphql/shim/fetchers/pipelinesAndWorkflows';

import {
  VCSType,
  APIPipeline,
  Job,
  Pipeline,
  APIPipelinesResponse,
} from '~/graphql/shim/types';
import rerunJobWithSsh from '~/graphql/shim/mutators/rerunJobWithSsh';
import approveJob from './mutators/approveJob';
import { project } from '@circleci/web-ui-data';
import logoutUser from './mutators/logoutUser';
import fetchPlanV2, { PlanV2 } from './fetchers/planV2';
import mapPlanV2 from './mappers/planV2';
import getIsEnterprise from '~/utils/getIsEnterprise';

export const workflowsResolver =
  (fetch: typeof window.fetch, apiKey: string) =>
  (workflowIds: string[]) =>
  () => {
    const promises = workflowIds.map(async (workflowId) => {
      const apiWorkflow = await fetchAPIWorkflow(fetch, apiKey, workflowId);
      return mapAPIWorkflowToWorkflow(apiWorkflow);
    });
    return Promise.all(promises);
  };

interface Deps {
  apiKey: string;
  fetch: typeof window.fetch;
}

interface CancelJobProps {
  vcsType: VCSType;
  username: string;
  project: string;
  buildNumber: string;
}

interface RerunSSHJobProps {
  workflowId: string;
  jobId: string;
}

export interface ConfigProps {
  pipelineId: string;
}

interface StepOutputProps {
  vcsType: VCSType;
  username: string;
  project: string;
  buildNumber: string;
  stepSlug: string;
  allocationId: string | undefined;
}
interface QueryPipelineProps {
  vcsType: VCSType;
  orgName: string;
  projectName: string;
  pipelineNumber: string;
}

interface PipelinesProps {
  vcsType: VCSType;
  username: string;
  project?: string;
  branch?: string;
  filter?: string;
  pageToken?: string;
  authed?: boolean;
  createdBefore?: string;
  isBffV2Enabled?: boolean;
}

interface ProjectBranchesProps {
  vcsType: VCSType;
  username: string;
  project: string;
}

interface LegacyJobsProps {
  vcsType: VCSType;
  username: string;
  project: string;
  offset: number;
}

interface OrgJobsProps {
  vcsType: VCSType;
  username: string;
  offset: number;
}

interface SshJobsProps {
  buildNumber: string;
  vcsType: VCSType;
  orgName: string;
  projectName: string;
}

interface TriggerPipelineProps {
  vcsType: VCSType;
  username: string;
  project: string;
  branch: string;
  parameters: string;
}

interface LatestConfigProps {
  vcsType: VCSType;
  orgName: string;
  projectName: string;
  branchName: string;
}

interface PlanV2Props {
  orgName: string;
  vcsType: VCSType;
}

const resolvers = {
  Job: {
    pipeline: (root: Pick<Job, 'pipelineId'>, _props: {}, deps: Deps) =>
      R.isNil(root.pipelineId)
        ? null
        : resolvers.Query.pipelineNumber(null, root, deps),
  },
  Pipeline: {
    projectV2: async (
      { projectSlug }: Pick<Pipeline, 'projectSlug'>,
      _props: {},
      { fetch, ...deps }: Deps,
    ) => {
      return project.resolvers.Query.project(
        null,
        { projectSlug },
        {
          ...deps,
          fetch(url, options) {
            return fetch(url, { ...options, cache: 'force-cache' });
          },
        },
      );
    },
  },

  Query: {
    config: async (
      _root: any,
      { pipelineId }: ConfigProps,
      { fetch, apiKey }: Deps,
    ) => {
      const apiConfig = await fetchAPIConfig(fetch, apiKey, pipelineId);
      return apiConfig;
    },
    job: async (
      _root: any,
      { buildNumber, vcsType, username, project }: any,
      { fetch, apiKey }: any,
    ) => {
      const apiJob = await fetchAPIJob(
        fetch,
        apiKey,
        vcsType,
        username,
        project,
        buildNumber,
      );

      const testResultsResolver = async () => {
        const apiTestResults = await fetchAPITestResults({
          fetch,
          apiKey,
          buildNumber,
          vcsType,
          username,
          project,
        });
        return mapAPITestResultsToGraphQL(apiTestResults);
      };

      let pipelineId = null;
      if (apiJob.workflows && apiJob.workflows.workflow_id) {
        const workflowResolver = workflowsResolver(
          fetch,
          apiKey,
        )([apiJob.workflows.workflow_id]);
        const workflowArray = await workflowResolver();
        const workflow = workflowArray[0];
        pipelineId = workflow.pipelineId;
      }
      return mapAPIJobToGraphQL(apiJob, testResultsResolver, pipelineId);
    },

    legacyJob: async (
      _root: any,
      { buildNumber, vcsType, username, project }: any,
      { fetch, apiKey }: any,
    ) => {
      const apiJob = await fetchAPIJob(
        fetch,
        apiKey,
        vcsType,
        username,
        project,
        buildNumber,
      );

      const testResultsResolver = async () => {
        const apiTestResults = await fetchAPITestResults({
          fetch,
          apiKey,
          buildNumber,
          vcsType,
          username,
          project,
        });
        return mapAPITestResultsToGraphQL(apiTestResults);
      };
      return mapAPILegacyJobToGraphQL(apiJob, testResultsResolver);
    },

    legacyJobs: async (_root: any, props: LegacyJobsProps, deps: Deps) => {
      const legacyJobs = await fetchAPILegacyJobs({ ...deps, ...props });
      return mapAPILegacyJobsToGraphQL(legacyJobs);
    },

    orgJobs: async (_root: any, props: OrgJobsProps, deps: Deps) => {
      const orgJobs = await fetchAPIOrgJobs({ ...deps, ...props });
      return mapAPIOrgJobsToGraphQL(orgJobs);
    },

    projectBranches: async (
      _root: any,
      { vcsType, username, project }: ProjectBranchesProps,
      { fetch, apiKey }: Deps,
    ) => {
      const projectBranches = await fetchAPIProjectBranches({
        fetch,
        apiKey,
        vcsType,
        username,
        project,
      });
      return mapAPIProjectsToProjectBranches(projectBranches);
    },

    projectInfo: async (
      _root: any,
      { vcsType, orgName, projectName }: any,
      { fetch, apiKey }: any,
    ) => {
      const apiProjectPromise = fetchAPIProjectInfo(
        fetch,
        apiKey,
        vcsType,
        orgName,
        projectName,
      );

      return mapAPIProjectInfoToGraphQL(await apiProjectPromise);
    },

    testSummary: async (
      _root: any,
      { vcsType, username, project, buildNumber }: any,
      { fetch, apiKey }: any,
    ) => {
      const apiTestResults = await fetchAPITestResults({
        fetch,
        apiKey,
        buildNumber,
        vcsType,
        username,
        project,
      });
      return mapAPITestResultsToGraphQL(apiTestResults);
    },
    artifacts: async (
      _root: any,
      { vcsType, username, project, buildNumber }: any,
      { fetch, apiKey }: any,
    ) => {
      const apiArtifacts = await fetchAPIArtifacts({
        fetch,
        apiKey,
        buildNumber,
        vcsType,
        username,
        project,
      });
      return mapAPIArtifactsToGraphQL(apiArtifacts);
    },
    stepOutput: async (
      _root: any,
      {
        vcsType,
        username,
        project,
        buildNumber,
        stepSlug,
        allocationId,
      }: StepOutputProps,
      { fetch, apiKey }: Deps,
    ) => {
      const outputPromise = fetchStepOutput(fetch, {
        apiKey,
        vcsType,
        username,
        project,
        buildNumber,
        stepSlug,
        allocationId,
      });

      const apiActionPromise = fetchAPIAction(
        fetch,
        apiKey,
        vcsType,
        username,
        project,
        buildNumber,
        stepSlug,
        allocationId,
      );

      return mapAPIStepOutputToGraphQL(
        await outputPromise,
        await apiActionPromise,
      );
    },
    pipeline: async (_root: any, props: QueryPipelineProps, deps: Deps) => {
      const apiPipeline = await fetchAPIPipelineByNumber({ ...deps, ...props });
      return mapAPIPipelineToGraphQL(apiPipeline);
    },
    pipelineWithWorkflows: async (
      _root: any,
      props: QueryPipelineProps,
      { fetch, apiKey }: any,
    ): Promise<any> => {
      const apiPipeline = await fetchAPIPipelineByNumber({
        fetch,
        apiKey,
        vcsType: props.vcsType,
        orgName: props.orgName,
        projectName: props.projectName,
        pipelineNumber: props.pipelineNumber,
      });

      const workflows = await fetchAPIPipelineWorkflows(
        fetch,
        apiKey,
        apiPipeline.id,
      );

      return mapAPIPipelineToPipilineWorkflowsGraphQL({
        apiPipeline,
        workflows,
      });
    },

    pipelineNumber: async (
      _root: any,
      { pipelineId }: any,
      { fetch, apiKey }: any,
    ) => {
      const apiPipeline = await fetchAPIPipeline(fetch, apiKey, pipelineId);
      return mapAPIPipelineToGraphQL(apiPipeline);
    },
    pipelines: async (
      _root: any,
      {
        vcsType,
        username,
        project,
        branch,
        filter,
        pageToken,
        authed,
        createdBefore,
        isBffV2Enabled,
      }: PipelinesProps,
      { fetch, apiKey }: Deps,
    ) => {
      if (authed && !getIsEnterprise()) {
        const apiPipelinesAndWorkflows = await fetchAPIPipelinesAndWorkflows({
          fetch,
          apiKey,
          vcsType,
          username,
          project,
          branch,
          filter,
          pageToken,
          createdBefore,
          isBffV2Enabled,
        });

        if (apiPipelinesAndWorkflows?.items?.length != 0) {
          return mapAPIPipelinesToGraphQL({
            items: (apiPipelinesAndWorkflows.items as any) || [],
            next_page_token: apiPipelinesAndWorkflows.next_page_token,
          });
        }
      }

      const apiPipelines: APIPipelinesResponse = await (project
        ? fetchAPIPipelines({
            fetch,
            apiKey,
            vcsType,
            username,
            project,
            branch,
            filter,
            pageToken,
          })
        : fetchAPICrossProjectPipelines({
            fetch,
            apiKey,
            vcsType,
            username,
            filter,
            pageToken,
          }));

      const fetchWorkflows = (pipelineId: string) =>
        fetchAPIPipelineWorkflows(fetch, apiKey, pipelineId);

      const updateWithWorkflows = async (apiPipeline: APIPipeline) => ({
        ...apiPipeline,
        workflows: await fetchWorkflows(apiPipeline.id),
      });

      const pipelinesPromises = R.map(
        // TODO: address the `any` or convert to vanillaJS
        updateWithWorkflows as any,
        apiPipelines.items,
      );
      const pipelines = await Promise.all(pipelinesPromises);

      return mapAPIPipelinesToGraphQL({
        items: pipelines as any,
        next_page_token: apiPipelines.next_page_token,
      });
    },
    workflow: async (
      _root: any,
      { workflowId }: any,
      { fetch, apiKey }: any,
    ) => {
      const apiWorkflow = await fetchAPIWorkflow(fetch, apiKey, workflowId);

      return mapAPIWorkflowToWorkflow(apiWorkflow);
    },
    workflowActions: async (_root: any, { workflowId }: any) => {
      const jobActions = await apiServiceWorkflowGraphJobs(workflowId);
      return jobActions;
    },
    workflowJobs: async (
      _root: any,
      { workflowId }: any,
      { fetch, apiKey }: any,
    ) => {
      const apiWorkflowJobsResponse = await fetchAPIWorkflowJobs(
        fetch,
        apiKey,
        workflowId,
      );

      return mapAPIWorkflowJobsResponseToGraphQL(apiWorkflowJobsResponse);
    },
    collaborations: async (
      _root: any,
      _params: any,
      { fetch, apiKey }: Deps,
    ) => {
      const collaborations = await fetchAPICollaborations(fetch, apiKey);
      return mapAPICollaborationsToGraphQL(collaborations);
    },
    sshJobs: async (
      _root: unknown,
      { buildNumber, vcsType, orgName, projectName }: SshJobsProps,
      { fetch, apiKey }: Deps,
    ) => {
      const fetchWithoutCache = (input: RequestInfo, init?: RequestInit) => {
        const noCacheInit = {
          ...init,
          cache: 'no-cache',
        } as RequestInit;
        return fetch(input, noCacheInit);
      };
      const parent = await fetchAPIJob(
        fetchWithoutCache,
        apiKey,
        vcsType,
        orgName,
        projectName,
        buildNumber,
      );

      const retries = parent?.retries ?? [];

      const sshJobs = await Promise.all(
        retries.map(async (retryBuildNumber) => {
          const job = await fetchAPIJob(
            fetch,
            apiKey,
            vcsType,
            orgName,
            projectName,
            String(retryBuildNumber),
          );

          if (R.isEmpty(job.ssh_users)) {
            return null;
          }

          return mapApiJobToSshJob(job);
        }),
      );

      return R.reject(R.isNil, sshJobs);
    },
    latestConfig: async (
      _root: unknown,
      props: LatestConfigProps,
      { fetch, apiKey }: Deps,
    ) => await fetchLatestConfig(fetch, apiKey, props),
    planV2: async (
      _root: unknown,
      { orgName, vcsType }: PlanV2Props,
    ): Promise<PlanV2> => {
      const response = await fetchPlanV2({ orgName, vcsType });
      return mapPlanV2(response);
    },
  },
  Mutation: {
    cancelJob: async (
      _root: any,
      { vcsType, username, project, buildNumber }: CancelJobProps,
      { fetch, apiKey }: Deps,
    ) => {
      return cancelJob(fetch, apiKey, vcsType, username, project, buildNumber);
    },
    rerunJobWithSsh: async (
      _root: any,
      { workflowId, jobId }: RerunSSHJobProps,
      { fetch, apiKey }: Deps,
    ) => {
      const response = await rerunJobWithSsh(fetch, apiKey, workflowId, jobId);

      // There is some necessary complexity here because the rerun workflow (which rerunwith SSH uses)
      // is a sync API. Because of the way it gets assigned a jobNumber in the WFC, there will be some 'blocked'
      // jobs that may not have a jobNumber yet as the build document has not been created
      // https://github.com/circleci/workflows-conductor/blob/e91b990648a45eb691b5fa21c63f3e27b14e370c/src/workflows_conductor/messaging.clj#L333-L337)
      // to protect against this, if the request to fetch the workflow Jobs contains a job that does not
      // have a jobNumber, we timeout for half a second and retry.
      await new Promise((resolve) => setTimeout(resolve, 300));

      const newWorkflowId = response?.workflow_id;

      const apiWorkflowJobsResponse = await fetchAPIWorkflowJobs(
        fetch,
        apiKey,
        newWorkflowId,
      );

      const newSSHJob = mapWorkflowJobsToNewRerunBuildNumber(
        apiWorkflowJobsResponse?.items,
        newWorkflowId,
      );

      if (newSSHJob?.buildNumber) {
        return newSSHJob;
      } else {
        // We try again, but with a slightly longer timeout
        await new Promise((resolve) => setTimeout(resolve, 800));
        const afterLongerTimeOutWorkflowJobs = await fetchAPIWorkflowJobs(
          fetch,
          apiKey,
          newWorkflowId,
        );

        const afterTimeoutNewSSHJob = mapWorkflowJobsToNewRerunBuildNumber(
          afterLongerTimeOutWorkflowJobs?.items,
          newWorkflowId,
        );

        return afterTimeoutNewSSHJob;
      }
    },
    rerunWorkflow: async (
      _root: any,
      { workflowId, from }: { workflowId: string; from: string },
      { fetch, apiKey }: Deps,
    ) => {
      return rerunWorkflow(fetch, apiKey, workflowId, from as any);
    },
    cancelWorkflow: async (
      _root: any,
      { workflowId }: { workflowId: string },
      { fetch, apiKey }: Deps,
    ) => {
      return cancelWorkflow(fetch, apiKey, workflowId);
    },
    approveJob: async (
      _root: any,
      { workflowId, jobId }: { workflowId: string; jobId: string },
      { fetch, apiKey }: Deps,
    ) => {
      return approveJob(fetch, apiKey, workflowId, jobId);
    },
    followProject: async (
      _root: any,
      props: { vcsType: VCSType; username: string; project: string },
      deps: Deps,
    ): Promise<any> => {
      await followProject({ ...deps, ...props });
      return null;
    },
    logoutUser: async (_root: any, _: any, { fetch, apiKey }: Deps) => {
      await logoutUser(fetch, apiKey);
    },
    unfollowProject: async (
      _root: any,
      props: { vcsType: VCSType; username: string; project: string },
      deps: Deps,
    ): Promise<any> => {
      await unfollowProject({ ...deps, ...props });
      return null;
    },
    triggerPipeline: async (
      _root: any,
      { vcsType, username, project, branch, parameters }: TriggerPipelineProps,
      { fetch, apiKey }: Deps,
    ) => {
      const parsedParameters = JSON.parse(parameters);
      return triggerPipeline(
        fetch,
        apiKey,
        vcsType,
        username,
        project,
        branch,
        parsedParameters,
      );
    },
  },
};

export default resolvers;
