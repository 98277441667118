import { client } from '@circleci/web-ui-data';

import {
  APIScheduledTrigger,
  APIParameters,
} from '~/project-settings/packages/triggers/graphql/fetchers/fetchScheduledTrigger';
import {
  Week,
  InputTriggerActor,
  Month,
} from '~/project-settings/packages/triggers/graphql/types';

export interface InputTimetable {
  'days-of-week'?: Week[];
  'hours-of-day'?: number[];
  'per-hour'?: number;
  'days-of-month'?: number[];
  months?: Month[];
}

export interface APIUpdateTrigger {
  name?: string;
  description?: string;
  'attribution-actor'?: Omit<InputTriggerActor, 'previous'>;
  timetable?: InputTimetable;
  parameters?: APIParameters;
}

interface Props {
  apiKey: string;
  fetch: typeof fetch;
  updatedTrigger: APIUpdateTrigger;
  scheduledTriggerId: string;
}

export const updateScheduledTrigger = async ({
  apiKey,
  fetch,
  updatedTrigger,
  scheduledTriggerId,
}: Props): Promise<APIScheduledTrigger> => {
  const pathname = `/api/v2/schedule/${scheduledTriggerId}`;
  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'PATCH',
    body: updatedTrigger,
  });

  return await response.json();
};
