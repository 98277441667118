import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

const logoutUser = async (fetch: Fetch, apiKey: string) => {
  const pathname = `/logout`;
  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    callerName: 'logoutUser',
  });
  return await response.json();
};
export default logoutUser;
