import { APIV2WorkflowJob, RerunWithSshJob } from '~/graphql/shim/types';

export function mapWorkflowJobsToNewRerunBuildNumber(
  newWorkflowJobs: APIV2WorkflowJob[],
  newWorkflowId: string,
): RerunWithSshJob {
  // The below is to find what the jobNumber of the rerun with SSH job is within a set of jobs in a workflow.
  // This is because the rerun workflow V2 API does not return the jobId or jobNumber of the rerun job(s)
  const newJobRunningWithSSHEnabled = newWorkflowJobs.filter((job) =>
    isSSHJob(job?.status),
  )[0];

  return {
    buildNumber: newJobRunningWithSSHEnabled?.job_number,
    newWorkflowId: newWorkflowId,
  };
}

function isSSHJob(status: string | undefined): boolean {
  if (!status) return false;
  // These are the possible job statuses for
  // a job that was rerun with SSH.
  // It can't be on_hold since that's for approvals
  // and it can't be blocked because there will be nothing downstream.
  return ['running', 'not_running', 'queued'].includes(status);
}
