import { ApiApiToken } from '~/project-settings/graphql/shim/fetchers/apiTokens';
import { APIToken } from '~/project-settings/graphql/types';

const scopeValues: Record<string, string> = {
  all: 'Admin',
  status: 'Status',
  'view-builds': 'Read Only',
};

export const mapApiToken = ({
  label,
  scope,
  token,
  time,
  id,
}: ApiApiToken) => ({
  label,
  scope: scopeValues[scope],
  token,
  id,
  createdAt: time,
});

export const mapApiTokens = (apiApiTokens: ApiApiToken[]): APIToken[] =>
  apiApiTokens.map(mapApiToken);
