import * as R from 'ramda';
import { client } from '@circleci/web-ui-data';
import { APIV2Workflow } from '~/graphql/shim/types';

const fetchPipelineWorkflows = async (
  fetch: typeof window.fetch,
  apiKey: string,
  pipelineId: string,
): Promise<APIV2Workflow[]> => {
  const pathname = `/api/v2/pipeline/${pipelineId}/workflow`;

  let items: APIV2Workflow[] = [];
  let nextPageToken: string = null as any;

  do {
    const query = R.reject(R.isNil, { 'page-token': nextPageToken }); // The API returns 500 on ?page-token=null
    const response = await client.apiFetcher({
      apiKey,
      fetch,
      pathname,
      query,
      callerName: 'fetchPipelineWorkflows',
    });
    const body = await response.json();

    nextPageToken = body.next_page_token;
    items = R.concat(items, body.items);
  } while (nextPageToken);

  return items;
};
export default fetchPipelineWorkflows;
