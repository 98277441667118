import * as React from 'react';
import { useRouter } from 'next/router';
import trackSharedLink from '~/utils/trackSharedLink';

const useTrackSharedLink = () => {
  const router = useRouter();
  const { pathname, query } = router;
  const referrer = document.referrer;

  React.useEffect(() => {
    trackSharedLink({
      query,
      referrer,
      pathname,
    });
  }, [pathname, query, referrer]);
};

export default useTrackSharedLink;
