import { client } from '@circleci/web-ui-data';
import { APIPipeline } from '~/graphql/shim/types';

const fetchPipeline = async (
  fetch: typeof window.fetch,
  apiKey: string,
  pipelineId: string,
): Promise<APIPipeline> => {
  const pathname = `/api/v2/pipeline/${pipelineId}`;
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    callerName: 'fetchPipeline',
  });

  return await response.json();
};
export default fetchPipeline;
