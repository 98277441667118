import { ProjectSlug } from '@circleci/web-ui-data/build/types/types';
import { VCSType } from '~/graphql/shim/types';
import { ShortVCS } from '~/types/types';

export const vcsTypeMap: Record<ShortVCS, VCSType> = {
  bb: 'bitbucket',
  gh: 'github',
  circleci: 'circleci',
};

const convertVcsType = (projectSlug: string): ProjectSlug | null => {
  if (!projectSlug) return null;

  const firstSlashIdx = projectSlug.indexOf('/');
  const shortVcsType = projectSlug.substring(0, firstSlashIdx) as ShortVCS;
  return (vcsTypeMap[shortVcsType] +
    projectSlug.substring(firstSlashIdx)) as ProjectSlug;
};

export default convertVcsType;
