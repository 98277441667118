import * as R from 'ramda';
import {
  APIPipelineError,
  PipelineMessage,
  PipelineMessageType,
} from '~/graphql/shim/types';

// We create this object to make sure it always contains all the value of `PipelineMessageType`.
const messageTypes: { [k in PipelineMessageType]: true } = {
  config: true,
  configFetch: true,
  plan: true,
  unknown: true,
};

const messageType = (apiType: string): PipelineMessageType => {
  if (apiType === 'config-fetch') {
    return 'configFetch';
  } else if (apiType in messageTypes) {
    return apiType as PipelineMessageType;
  } else {
    return 'unknown';
  }
};

const pipelineMessage = (errors: APIPipelineError[], type: string) => ({
  type: messageType(type),
  messages: errors.map((error) => error.message),
});

const pipelineMessages = (apiErrors: APIPipelineError[]): PipelineMessage[] =>
  R.pipe(
    R.groupBy<APIPipelineError>(R.prop('type')),
    R.mapObjIndexed(pipelineMessage),
    R.values,
  )(apiErrors);

export default pipelineMessages;
