/*
 * Note: This component was created so that the 'useTrackSharedLink()' hook
 * could be called in the _app page. Because the app page is constructed with a
 * Class Component, it doesn't support calling hooks inside of it.
 */

import useTrackSharedLink from '~/hooks/useTrackSharedLink';

const TrackSharedLink: () => any = () => {
  useTrackSharedLink();
  return null;
};

export default TrackSharedLink;
