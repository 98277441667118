import * as R from 'ramda';

import {
  APITestResult,
  TestResult,
  APITestResponse,
  TestSummary,
} from '~/graphql/shim/types';

const testResult = (apiTestResult: APITestResult): TestResult => ({
  fileName: apiTestResult.file,
  className: apiTestResult.classname,
  message: apiTestResult.message,
  name: apiTestResult.name,
  result: apiTestResult.result,
  runTime: apiTestResult.run_time,
  source: apiTestResult.source,
  sourceType: apiTestResult.source_type,
});

const isError = R.propEq('result', 'error');
const isFailure = R.propEq('result', 'failure');

const getTotalCount = R.pipe<[APITestResponse], APITestResult[], number>(
  R.propOr([], 'tests'),
  R.length,
);

const exception = (output: string[]) => ({ output });

const testResults = (apiTestResponse: APITestResponse): TestSummary => {
  // TODO: address the `any` or convert to vanillaJS
  const failed = R.pipe<
    [APITestResponse],
    APITestResult[],
    TestResult[],
    TestResult[]
  >(
    R.propOr([], 'tests'),
    R.map(testResult),
    R.filter<TestResult>(R.anyPass([isFailure, isError]) as any),
  )(apiTestResponse);

  const exceptions = R.map(
    exception,
    R.propOr([], 'exceptions', apiTestResponse),
  );

  const totalCount = getTotalCount(apiTestResponse);

  return {
    exceptions,
    exceptionCount: R.length(exceptions),
    failed,
    failedCount: R.length(failed),
    totalCount: totalCount,
    isSetup: !!totalCount,
  };
};

export default testResults;
