import { VCSType, APIArtifacts } from '~/graphql/shim/types';
import { client } from '@circleci/web-ui-data';

export interface ArtifactsArgs {
  fetch: any;
  apiKey: string;
  vcsType: VCSType;
  username: string;
  project: string;
  buildNumber: string;
}

const fetchAPIArtifacts = async ({
  fetch,
  apiKey,
  vcsType,
  username,
  project,
  buildNumber,
}: ArtifactsArgs): Promise<APIArtifacts> => {
  const pathname = `/api/v1.1/project/${vcsType}/${username}/${project}/${buildNumber}/artifacts`;

  return (
    await client.apiFetcher({
      apiKey,
      fetch,
      pathname,
      callerName: 'fetchAPIArtifacts',
    })
  ).json();
};

export default fetchAPIArtifacts;
