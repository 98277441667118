import * as R from 'ramda';

import { APIStepOutput, ParallelRunStepOutput } from '~/graphql/shim/types';

const removeTrailingNewline = R.replace(/\r?\n$/, '');

const toStepOutput = (apiStepOutput: APIStepOutput): ParallelRunStepOutput => ({
  messages: R.split(/\r?\n/, removeTrailingNewline(apiStepOutput.message)),
  sequence: apiStepOutput.sequence,
  truncated: apiStepOutput.truncated,
});

interface APIAction {
  bash_command?: string;
}

const mapAPIStepOutputToGraphQL = (
  apiStepOutput: APIStepOutput[],
  { bash_command }: APIAction,
) => {
  const bashOutput = {
    bashCommand: bash_command,
    messages: [] as any,
    sequence: -1,
  };
  const stepOutputs = R.isNil(apiStepOutput)
    ? []
    : R.map(toStepOutput, apiStepOutput);
  return [bashOutput, ...stepOutputs];
};

export default mapAPIStepOutputToGraphQL;
