import { fetchScheduledTriggersList } from '~/project-settings/packages/triggers/graphql/fetchers/fetchScheduledTriggersList';
import { mapScheduledTriggersList } from '~/project-settings/packages/triggers/graphql/mappers/mapScheduledTriggersList';
import { Deps } from '~/project-settings/packages/triggers/graphql/resolvers/types';
import { QueryToScheduledTriggersArgs } from '~/project-settings/packages/triggers/graphql/types';

export const getScheduledTriggersList = async (
  _root: any,
  props: QueryToScheduledTriggersArgs,
  deps: Deps,
) => {
  const scheduledTriggersList = await fetchScheduledTriggersList({
    ...deps,
    ...props,
  });

  return mapScheduledTriggersList(
    scheduledTriggersList.items,
    scheduledTriggersList.next_page_token,
  );
};
