import apiServiceClient from '~/graphql/apiServiceClient';
import gql from 'graphql-tag';

const query = gql`
  query WorkflowGraph($workflowId: ID!) {
    workflow(id: $workflowId) {
      jobs {
        actions {
          actor {
            avatarURL
            id
            name
          }
          type
        }
        buildNumber
        id
      }
    }
  }
`;

const apiServiceWorkflowGraphJobs = async (workflowId: string) => {
  const response = await apiServiceClient.query({
    query,
    variables: { workflowId },
    fetchPolicy: 'network-only',
  });
  return response.data.workflow.jobs;
};

export default apiServiceWorkflowGraphJobs;
