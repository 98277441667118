import { APIScheduledTrigger } from '~/project-settings/packages/triggers/graphql/fetchers/fetchScheduledTrigger';
import {
  ParameterType,
  ScheduledTrigger,
  BranchOrTagType,
} from '~/project-settings/packages/triggers/graphql/types';

export const mapScheduledTrigger = ({
  timetable,
  parameters,
  'created-at': created_at,
  ...apiScheduledTrigger
}: APIScheduledTrigger): ScheduledTrigger => {
  const { branch, tag, ...restParameters } = parameters;
  delete apiScheduledTrigger['updated-at'];
  return {
    ...apiScheduledTrigger,
    created_at,
    branchOrTag: {
      // @ts-ignore
      value: branch || tag,
      type: branch ? BranchOrTagType.branch : BranchOrTagType.tag,
    },
    timetable: {
      per_hour: timetable['per-hour'],
      days_of_week: timetable['days-of-week'],
      hours_of_day: timetable['hours-of-day'],
      days_of_month: timetable['days-of-month'],
      months: timetable.months,
    },
    parameters: Object.entries(restParameters).map(
      ([parameterName, parameterValue]) => {
        const parameterType = typeof parameterValue;
        return {
          name: parameterName,
          type: (parameterType === 'number'
            ? 'integer'
            : parameterType) as ParameterType,
          value: parameterValue.toString(),
        };
      },
    ),
  };
};
