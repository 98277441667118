import { keyframes, css } from '@emotion/core';
import * as React from 'react';
import { CircleLogoIcon, useCompassTheme } from '@circleci/compass';
import styled from '~/styled';

import LoadingIcon from '~/components/shared-components/LoadingIcon';

const loadingIndicatorDelay = 1;

const keyFrame = keyframes`
99% {
  visibility: hidden;
  opacity: 0;
}
100% {
  visibility: visible;
  opacity: 1;
}
`;

// keyframes from emotion has interporation
// issues when passed into a styled function.
// It's best to use keyframe in a template
// literal.
const Wrapper = styled.div(
  ({ theme }) => css`
    visibility: hidden;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    animation: ${keyFrame} ${loadingIndicatorDelay}s ease;
    animation-fill-mode: forwards;
    > :not(:last-child) {
      margin-right: ${theme.space.space8};
    }
    > :last-child {
      margin-left: ${theme.space.space16};
    }
  `,
);

const Text = styled.span(
  ({ theme }) => css`
    margin: 0;
    padding: 0;
    font-weight: ${theme.fontWeights.bold};
    font-size: ${theme.fontSizes.fontsize16};
  `,
);

const IconWrap = styled.div(
  ({ theme }) => `
  border-left: 1px solid ${theme.colors.n80};
  > span {
    margin-left: ${theme.space.space4};
  }
`,
);

const LoadingIndicator: React.FC = () => {
  const theme = useCompassTheme();

  return (
    <Wrapper>
      <CircleLogoIcon size={theme.space.space24} color={theme.colors.n900} />
      <Text>Loading</Text>
      <IconWrap>
        <LoadingIcon />
      </IconWrap>
    </Wrapper>
  );
};

export default LoadingIndicator;
