import * as R from 'ramda';
import { APIJob, TestSummary, LegacyJob } from '~/graphql/shim/types';
import {
  mapExecutor,
  mapClass,
  mapRam,
  infrastructureFailMessages,
  messages,
  workflow,
  isDone,
  parallelRuns,
  jobId,
  jobName,
  actor,
  commits,
} from '~/graphql/shim/mappers/job';

const mapAPILegacyJobToGraphQL = (
  apiJob: APIJob,
  testSummaryResolver: () => Promise<TestSummary>,
): Omit<LegacyJob, 'commitSubject'> => ({
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  actor: actor(apiJob.user) as any,
  buildNumber: apiJob.build_num,
  buildUrl: apiJob.build_url,
  commits: apiJob.all_commit_details
    ? commits(apiJob.all_commit_details)
    : null,
  commitsTruncated: apiJob.all_commit_details_truncated || false,
  id: jobId(apiJob.workflows),
  name: jobName(apiJob.workflows),
  legacyName: apiJob.job_name || null,
  parallelRuns: parallelRuns(jobId(apiJob.workflows), apiJob.steps),
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  startedAt: apiJob.start_time!,
  usageQueuedAt: R.pathOr(null, ['usage_queued_at'], apiJob),
  queuedAt: R.pathOr(null, ['queued_at'], apiJob),
  done: isDone(apiJob.status),
  status: R.toUpper(apiJob.status),
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  stoppedAt: apiJob.stop_time!,
  testSummary: testSummaryResolver,
  workflow: workflow(apiJob)!,
  vcsUrl: apiJob.vcs_url,
  vcsRevision: apiJob.vcs_revision,
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  why: apiJob.why!,
  branch: apiJob.branch,
  messages: R.concat(
    messages(apiJob.messages),
    infrastructureFailMessages(apiJob.steps),
  ),
  resourceCpu: R.pathOr(0, ['picard', 'resource_class', 'cpu'], apiJob),
  // The API provides us with a RAM value in megabits, but we want to show users gigabytes
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  resourceRam: mapRam(apiJob?.picard?.resource_class?.ram ?? 0)!,
  resourceClass: mapClass(
    apiJob?.picard?.resource_class?.class as any,
    apiJob?.picard?.executor as any,
  ),
  resourceName: apiJob.picard?.resource_class.name ?? undefined,
  resourceExecutor: mapExecutor(apiJob?.picard?.executor as any),
  platform: apiJob.platform || undefined,
  oss: !!apiJob.oss,
});

export default mapAPILegacyJobToGraphQL;
