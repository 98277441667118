import { client } from '@circleci/web-ui-data';
import { VCSType } from '~/graphql/shim/types';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: VCSType;
  username: string;
  project: string;
}

const mutateUnfollowProject = async ({
  apiKey,
  fetch,
  vcsType,
  username,
  project,
}: Props) => {
  const pathname = `/api/v1.1/project/${vcsType}/${username}/${project}/unfollow`;
  await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'POST',
    callerName: 'mutateUnfollowProject',
  });
};
export default mutateUnfollowProject;
