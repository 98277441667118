import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
}

export interface APIUserOrganization {
  vcs_type: string;
  login: string;
  admin: boolean;
}

const fetchUserOrganizations = async ({
  apiKey,
  fetch,
}: Props): Promise<APIUserOrganization[]> => {
  const pathname = '/api/v1.1/user/organizations';
  const query = { 'include-user': 'true' };
  const response = await client.apiFetcher({ apiKey, fetch, pathname, query });
  return await response.json();
};

export default fetchUserOrganizations;
