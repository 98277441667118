import { client } from '@circleci/web-ui-data';
import * as R from 'ramda';

import { WebhookUpdateInput } from '~/project-settings/graphql/types';
import { APIWebhook } from '~/project-settings/graphql/types/webhook';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  webhook: WebhookUpdateInput;
}

const mutateUpdateWebhook = async ({
  apiKey,
  fetch,
  webhook,
}: Props): Promise<APIWebhook> => {
  const { id, signing_secret, verify_tls } = webhook;
  const pathname = `/api/v2/webhook/${id}`;
  const requestBody = R.omit(
    ['id', 'scope', 'signing_secret', 'verify_tls'],
    webhook,
  );

  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'PUT',
    body: {
      ...requestBody,
      // /api/v2 input keys are kebab-case by convention
      'signing-secret': signing_secret,
      'verify-tls': verify_tls,
    },
  });
  return await response.json();
};
export default mutateUpdateWebhook;
