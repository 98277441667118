import { APIGithubOauthInfo } from '~/project-settings/graphql/shim/fetchers/githubOauthInfo';

const mapAdvancedSettings = ({
  oauth_csrf_token,
  auth_protocol,
  auth_host,
  github_http_endpoint,
  github_client_id,
}: APIGithubOauthInfo) => ({
  oauthCsrfToken: oauth_csrf_token,
  authProtocol: auth_protocol,
  authHost: auth_host,
  githubHttpEndpoint: github_http_endpoint,
  githubClientId: github_client_id,
});

export default mapAdvancedSettings;
