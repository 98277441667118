import { client } from '@circleci/web-ui-data';
import * as R from 'ramda';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
  fingerprint: string;
}

type PathProps = Pick<
  Props,
  'vcsType' | 'orgName' | 'projectName' | 'fingerprint'
>;

const pathname = (props: PathProps) => {
  const { vcsType, orgName, projectName, fingerprint } = R.mapObjIndexed(
    encodeURIComponent,
    props,
  );
  return `/api/v2/project/${vcsType}/${orgName}/${projectName}/checkout-key/${fingerprint}`;
};

const mutateDeleteCheckoutKey = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
  fingerprint,
}: Props) => {
  await client.apiMutator({
    apiKey,
    fetch,
    pathname: pathname({ vcsType, orgName, projectName, fingerprint }),
    method: 'DELETE',
    body: {},
  });
};
export default mutateDeleteCheckoutKey;
