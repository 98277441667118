import { client } from '@circleci/web-ui-data';

interface Props {
  fetch: typeof window.fetch;
}

export interface APIGithubOauthInfo {
  oauth_csrf_token: string;
  auth_protocol: string;
  auth_host: string;
  github_http_endpoint: string;
  github_client_id: string;
}

const fetchGithubOauthInfo = async ({
  fetch,
}: Props): Promise<APIGithubOauthInfo> => {
  const pathname = `/api/v1.1/github-oauth-info`;
  const response = await client.apiFetcher({
    apiKey: undefined,
    fetch,
    pathname,
  });
  return await response.json();
};

export default fetchGithubOauthInfo;
