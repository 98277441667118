import { client } from '@circleci/web-ui-data';

import { ProjectInfoDetails } from '~/project-settings/graphql/types';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
}

export interface APIProjectInfo {
  reponame: string;
  vcs_url: string;
}

const fetchProjectInfo = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
}: Props): Promise<ProjectInfoDetails> => {
  const pathname = `/api/v1.1/project/${vcsType}/${orgName}/${projectName}/info`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname });

  return response.json();
};

export default fetchProjectInfo;
