import ExperimentProvider, {
  createClient,
} from '@circleci/react-experiments/build/utils/ExperimentProvider';
import * as React from 'react';
import { ReactSDKClient } from '@optimizely/react-sdk';

import MeContext from '~/contexts/MeContext';
import getIsEnterprise from '~/utils/getIsEnterprise';
import PageLoading from '~/components/shared-components/PageLoading';

const OptimizelyFullStackProvider = ({ children }: any) => {
  const { me } = React.useContext(MeContext);
  const [optimizelyClient, setOptimizelyClient] =
    React.useState<ReactSDKClient>();
  const [optimizelyClientReady, setOptimizelyClientReady] =
    React.useState(false);

  React.useEffect(() => {
    if (!optimizelyClient) {
      const client = createClient(getIsEnterprise(), 'error');
      setOptimizelyClient(client);
    }

    if (optimizelyClient) {
      optimizelyClient
        .onReady({
          timeout: 5000,
        })
        .then(() => {
          setOptimizelyClientReady(true);
        });
    }
  }, [optimizelyClient]);

  // Avoid rendering ExperimentProvider without optimizelyClient being set since that would
  // cause a duplicate initialization of the Optimizely client due to the default props logic
  if (!optimizelyClient) return <PageLoading />;

  return (
    <ExperimentProvider optimizelyClient={optimizelyClient} me={me}>
      {optimizelyClientReady ? children : <PageLoading />}
    </ExperimentProvider>
  );
};

export default OptimizelyFullStackProvider;
