import { client } from '@circleci/web-ui-data';
import { VCSType } from '~/graphql/shim/types';

type Fetch = typeof fetch;

interface Props {
  apiKey: string;
  fetch: Fetch;
  vcsType: VCSType;
  username: string;
  project: string;
}

const followProject = async ({
  fetch,
  apiKey,
  vcsType,
  username,
  project,
}: Props): Promise<void> => {
  const pathname = `/api/v1.1/project/${vcsType}/${username}/${project}/follow`;
  await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    callerName: 'followProject',
  });
};
export default followProject;
