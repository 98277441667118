import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  id: string;
}

const mutateDeleteWebhook = async ({ apiKey, fetch, id }: Props) => {
  const pathname = `/api/v2/webhook/${id}`;

  await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'DELETE',
    body: {},
  });
};
export default mutateDeleteWebhook;
