import * as R from 'ramda';

import fetchJob from '~/graphql/shim/fetchers/job';
import { APIJob, APIStep, APIAction } from '../types';
import { parseStepSlug } from '~/utils/step';
import { VCSType } from '~/graphql/shim/types';

const extractActions = R.pipe<[APIJob], APIStep[], APIAction[][], APIAction[]>(
  R.prop('steps'),
  R.map(R.prop('actions')),
  R.unnest,
);

interface FindStepProps {
  stepSlug: string;
  allocationId: string | undefined;
}

const findStep = (
  { stepSlug, allocationId }: FindStepProps,
  actions: APIAction[],
): APIAction => {
  const { parallelRunIndex, stepIndex } = parseStepSlug(stepSlug);

  return R.find(
    R.where({
      index: R.equals(parallelRunIndex),
      step: R.equals(stepIndex),
      allocation_id: R.equals(allocationId),
    }),
    actions,
  ) as any;
};

const fetchAPIAction = async (
  fetch: typeof window.fetch,
  apiKey: string,
  vcsType: VCSType,
  username: string,
  project: string,
  buildNumber: string,
  stepSlug: string,
  allocationId: string | undefined,
) => {
  const apiJob = await fetchJob(
    fetch,
    apiKey,
    vcsType,
    username,
    project,
    buildNumber,
  );

  const allActions = extractActions(apiJob);
  return findStep({ stepSlug, allocationId }, allActions);
};

export default fetchAPIAction;
