import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  id: string;
}

const mutatePingWebhook = async ({ apiKey, fetch, id }: Props) => {
  const pathname = `/api/private/webhook/${id}/ping`;

  await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'POST',
    body: {},
  });
};
export default mutatePingWebhook;
