import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
}

export interface APIProjectSettings {
  following?: boolean;
  feature_flags: {
    'set-github-status'?: boolean;
    'build-fork-prs'?: boolean;
    'forks-receive-secret-env-vars'?: boolean;
    'build-prs-only'?: boolean;
    'autocancel-builds'?: boolean;
    oss?: boolean;
    'setup-workflows'?: boolean;
  };
  jira: null | {
    base_url: string;
    circle_token: string;
  };
  ssh_keys: {
    fingerprint: string;
    hostname: string;
  }[];
  aws?: {
    keypair: {
      access_key_id: string;
      secret_access_key: string;
    };
  };
  scopes: string[];
}

const query = {
  'ssh-key-digest': 'sha256',
};

const fetchProjectSettings = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
}: Props): Promise<APIProjectSettings> => {
  const pathname = `/api/v1.1/project/${vcsType}/${orgName}/${projectName}/settings`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname, query });
  return await response.json();
};

export default fetchProjectSettings;
