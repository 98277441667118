import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import { VCSType, Branch, ProjectBranches } from '~/graphql/shim/types';

const query = gql`
  query ProjectBranches(
    $vcsType: String!
    $username: String!
    $project: String!
  ) {
    projectBranches(vcsType: $vcsType, username: $username, project: $project) {
      items {
        id
        name
      }
      default
    }
  }
`;

interface Props {
  vcsType: VCSType;
  username: string;
  project: string;
  skip?: boolean;
}

export interface Data {
  projectBranches: ProjectBranches;
}

const useProjectBranches = (props: Props) => {
  const { error, loading, data, refetch } = useQuery<Data>(query, {
    variables: props,
    notifyOnNetworkStatusChange: true,
    skip: props.skip,
  });

  if (error || loading) return { error, loading };

  const branches = data?.projectBranches?.items?.map(
    (branch: Branch) => branch.name,
  );

  const defaultBranch = data?.projectBranches?.default;

  return { loading: false, branches, defaultBranch, refetch };
};

export default useProjectBranches;
