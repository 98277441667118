import { VCSType, APIPipeline } from '~/graphql/shim/types';
import { client } from '@circleci/web-ui-data';

interface Props {
  fetch: typeof window.fetch;
  apiKey: string;

  vcsType: VCSType;
  orgName: string;
  projectName: string;
  pipelineNumber: string;
}

const fetchAPIPipelineByNumber = async ({
  fetch,
  apiKey,
  vcsType,
  orgName,
  projectName,
  pipelineNumber,
}: Props): Promise<APIPipeline> => {
  const pathname = `/api/v2/project/${vcsType}/${orgName}/${projectName}/pipeline/${pipelineNumber}`;
  const res = await client.apiFetcher({
    fetch,
    apiKey,
    pathname,
    callerName: 'fetchAPIPipelineByNumber',
  });
  return (await res.json()) as unknown as APIPipeline;
};

export default fetchAPIPipelineByNumber;
