import { APIV2Workflow } from '~/graphql/shim/types';
import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

const fetchWorkflow = async (
  fetch: Fetch,
  apiKey: string,
  workflowId: string,
): Promise<APIV2Workflow> => {
  const pathname = `/api/v2/workflow/${workflowId}`;
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    callerName: 'fetchWorkflow',
  });
  return await response.json();
};
export default fetchWorkflow;
