import * as R from 'ramda';

import { APIJob } from '~/graphql/shim/types';
import { SshJob } from '../types';

const mapApiJobToSshJob = (apiJob: APIJob): SshJob => {
  return {
    id: `${apiJob.job_id}/${apiJob.build_num}`,
    buildNumber: apiJob.build_num,
    startedAt: apiJob?.start_time as string,
    stoppedAt: apiJob?.stop_time as string,
    status: R.toUpper(apiJob.status),
  };
};

export default mapApiJobToSshJob;
