import { subDays } from 'date-fns';
import * as R from 'ramda';

import { APIRepo, Repo } from '~/engagement-experiments/graphql/shim/types';
import { VCSType } from '~/graphql/shim/types';
import { SeenRepos } from '~/engagement-experiments/utils/seenRepos';

// the name field return from bitbucket can contain spaces so we are parsing
// the vcs_url to get the url encoded project's name
const parseName = (url: string): string => url.split('/').slice(-1)[0];

const isRecent = (timestamp?: string) => {
  if (R.isNil(timestamp)) return false;

  return new Date(timestamp) > subDays(new Date(), 7);
};

type CanBeSetupProps = Pick<APIRepo, 'following' | 'has_followers' | 'admin'>;
const canBeSetup = ({ following, has_followers, admin }: CanBeSetupProps) =>
  admin && !following && !has_followers;

interface HasBeenSeenProps {
  seenRepos: SeenRepos;
  vcsType: VCSType;
  org: string;
  name: string;
}

const hasBeenSeen = ({ seenRepos, vcsType, org, name }: HasBeenSeenProps) =>
  R.hasPath([vcsType, org, name], seenRepos);

const repo = (
  {
    vcs_type,
    vcs_url,
    username,
    owner,
    following,
    has_followers,
    admin,
    pushed_at,
    language,
  }: APIRepo,
  seenRepos: SeenRepos,
): Repo => ({
  name: vcs_url && parseName(vcs_url),
  org: username,
  vcsType: vcs_type,
  avatarUrl: owner.avatar_url,
  following,
  admin,
  hasFollowers: has_followers,
  language,
  isNew:
    isRecent(pushed_at) &&
    canBeSetup({ following, has_followers, admin }) &&
    !hasBeenSeen({
      seenRepos,
      vcsType: vcs_type,
      org: username,
      name: vcs_url && parseName(vcs_url),
    }),
});

export default repo;
