import { ProjectSlug } from '@circleci/web-ui-data/build/types/types';
import { VCSType } from '@circleci/web-ui-data/build/utils/types';
import { useQuery } from '@tanstack/react-query';
import unfetch from 'unfetch';
import { parseSlug } from '~/utils/decoupling';
import getHostname from '~/utils/getHostname';

import windowHasFetch from '~/utils/windowHasFetch';

export type APIResponse = {
  followed_projects: {
    name: string;
    slug: string;
    id: string;
    org_id: string;
  }[];
};

export type ProjectInfo = {
  name: string;
  slug: string;
  id: string;
  vcsType: VCSType;
  // username is really unfortunate naming, it's there for backwards compatible purposes
  username: string;
  // project is really unfortunate naming, it's there for backwards compatible purposes
  project: string;
};

interface Props {
  organizationId: string | undefined;
}

const fetch = windowHasFetch() ? window.fetch : unfetch;

const mapper = (data?: APIResponse, organizationId?: string) => {
  if (data && organizationId) {
    return data.followed_projects
      .filter((project) => project.org_id == organizationId)
      .map((project) => {
        return {
          name: project.name,
          slug: project.slug,
          id: project.id,
          vcsType: parseSlug(project.slug as ProjectSlug)[0],
          // This can be very confusing, orgName could be 58. orgId is the uuid
          username: organizationId,
          project: project.name,
        };
      });
  }
  return [];
};

export const useFollowedProjects = ({ organizationId }: Props) => {
  const url = `https://${getHostname()}/api/private/me`;
  /* eslint-disable */
  const { isInitialLoading: isLoading, data, error } = useQuery<APIResponse>(
    [`private-me${organizationId}`],
    async () => {
      const res = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });

      if (!res.ok) {
        throw new Error(`Received non 2xx from private/me: ${res.status}`);
      }

      return await res.json();
    },
    {
      enabled: !!organizationId
    }
  );

  return {
    error: error as Error | undefined,
    loading: isLoading,
    followedProjects: mapper(data, organizationId) as ProjectInfo[],
  };
};
