import { client } from '@circleci/web-ui-data';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
  fingerprint: string;
  hostname?: string | undefined;
}

const mutateDeleteSshKey = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
  fingerprint,
  hostname,
}: Props) => {
  const pathname = `/api/v1.1/project/${vcsType}/${orgName}/${projectName}/ssh-key`;

  await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    method: 'DELETE',
    body: { fingerprint, hostname },
  });
};
export default mutateDeleteSshKey;
