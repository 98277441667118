import { client } from '@circleci/web-ui-data';
import { APIJob, VCSType } from '~/graphql/shim/types';

const fetchAPIJob = async (
  fetch: any,
  apiKey: string,
  vcsType: VCSType,
  username: string,
  project: string,
  buildNumber: string,
): Promise<APIJob> => {
  const pathname = `/api/v1.1/project/${vcsType}/${username}/${project}/${buildNumber}`;
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    callerName: 'fetchAPIJob',
  });

  const job = await response.json();
  job.using_new_steps_api = response.headers.get('x-circle-steps') === 'true';

  return job;
};

export default fetchAPIJob;
