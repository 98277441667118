import { client } from '@circleci/web-ui-data';
import { VCSType } from '~/graphql/shim/types';

type Fetch = typeof fetch;

const cancelJob = async (
  fetch: Fetch,
  apiKey: string,
  vcsType: VCSType,
  username: string,
  project: string,
  buildNumber: string,
): Promise<void> => {
  const pathname = `/api/v1.1/project/${vcsType}/${username}/${project}/${buildNumber}/cancel`;
  await client.apiMutator({ apiKey, fetch, pathname, callerName: 'cancelJob' });
};
export default cancelJob;
