interface CustomError extends Error {
  status?: number;
}

export const asError = (obj: unknown): CustomError | undefined => {
  if (obj === undefined || obj === null) return;
  if (obj instanceof Error) return obj;
  if (typeof obj === 'string') return new Error(obj);

  return new Error(`Unknown error value: ${obj}`);
};
