import { client } from '@circleci/web-ui-data';
import { APICollaborations } from '~/graphql/shim/types';

const fetchCollaborations = async (
  fetch: any,
  apiKey: string,
): Promise<APICollaborations> => {
  const pathname = `/api/v2/me/collaborations`;
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    callerName: 'fetchCollaborations',
  });
  return await response.json();
};
export default fetchCollaborations;
