import { APIArtifacts, Artifacts } from '~/graphql/shim/types';

const mapAPIArtifactsToGraphQL = (apiArtifacts: APIArtifacts): Artifacts =>
  apiArtifacts.map(({ url, pretty_path, node_index }) => ({
    url,
    path: pretty_path,
    parallelRunIndex: node_index,
  }));

export default mapAPIArtifactsToGraphQL;
