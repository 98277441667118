import { client } from '@circleci/web-ui-data';
import { VCSType } from '~/graphql/shim/types';

type Fetch = typeof fetch;

const triggerPipeline = async (
  fetch: Fetch,
  apiKey: string,
  vcsType: VCSType,
  username: string,
  project: string,
  branch: string,
  parameters: { [key: string]: string | number | boolean },
): Promise<{ id: string; state: string; number: number }> => {
  const pathname = `/api/v2/project/${vcsType}/${username}/${project}/pipeline`;

  const body =
    Object.keys(parameters).length > 0 ? { branch, parameters } : { branch };

  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    body,
    callerName: 'triggerPipeline',
  });

  return await response.json();
};

export default triggerPipeline;
