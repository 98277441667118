import { client } from '@circleci/web-ui-data';

import { APIScheduledTrigger } from '~/project-settings/packages/triggers/graphql/fetchers/fetchScheduledTrigger';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  vcsType: string;
  orgName: string;
  projectName: string;
  pageToken?: string;
}

export interface PaginatedAPIReponse {
  items: APIScheduledTrigger[];
  next_page_token?: string;
}

export const fetchScheduledTriggersList = async ({
  apiKey,
  fetch,
  vcsType,
  orgName,
  projectName,
  pageToken,
}: Props): Promise<PaginatedAPIReponse> => {
  const pathname = `/api/v2/project/${vcsType}/${orgName}/${projectName}/schedule`;
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    query: { ...(pageToken && { 'page-token': pageToken }) },
  });

  return await response.json();
};
