import { deleteScheduledTrigger as deleteScheduledTriggerMutator } from '~/project-settings/packages/triggers/graphql/mutators/deleteScheduledTrigger';
import { Deps } from '~/project-settings/packages/triggers/graphql/resolvers/types';
import { MutationToDeleteScheduledTriggerArgs } from '~/project-settings/packages/triggers/graphql/types';

export const deleteScheduledTrigger = async (
  _root: any,
  props: MutationToDeleteScheduledTriggerArgs,
  deps: Deps,
) => {
  await deleteScheduledTriggerMutator({ ...deps, ...props });
  return null;
};
