import * as R from 'ramda';
import {
  APIAction,
  APICommit,
  APIJob,
  APIPullRequest,
  APIStep,
  Commit,
  Job,
  ParallelRun,
  PullRequest,
  Workflow,
  TestSummary,
  APIActor,
  JobMessage,
  APIJobMessage,
  JobMessageType,
} from '~/graphql/shim/types';
import global from '~/utils/global';

// TODO: address the `any` or convert to vanillaJS
const firstAction: (input: any[]) => any = R.pipe(
  R.sortBy(R.prop('start_time')),
  R.head,
);
// TODO: address the `any` or convert to vanillaJS
const lastAction: (input: any[]) => any = R.pipe(
  R.sortBy(R.prop('start_time')),
  R.last,
);

type InfraFailureAction = Pick<APIAction, 'allocation_id' | 'start_time'>;

/**
 * rejectInfraFailures finds the last first step and uses its allocation-id to
 * filter the apiActions.
 */
export const rejectInfraFailures = <T extends InfraFailureAction>(
  apiActions: T[],
): T[] => {
  const groupedActions = R.groupBy(R.prop('allocation_id'), apiActions);

  const firstActions = R.map(firstAction, R.values(groupedActions));
  const lastFirstAction = lastAction(firstActions);

  const allocationId = R.prop('allocation_id' as any, lastFirstAction);
  return apiActions.filter((action) => action?.allocation_id === allocationId);
};

const propIndexToString = R.pipe(R.prop('index'), R.toString);

// eslint-disable sort-keys
const StatusPriorities = {
  failed: 1,
  cancelled: 2,
  canceled: 2,
  running: 3,
  waiting: 4,
  success: 5,
  // 6: Left blank so it can be used for unknown statuses in statusPriority
  empty: 7,
};
// eslint-enable sort-keys

export const statusPriority = (status: string): number =>
  R.propOr(6, status, StatusPriorities);

const rejectInsignificantActions = R.reject(
  R.propEq('insignificant', true) as (action: AggregatableAction) => boolean,
);

const findMostImportantStatus = R.reduce<string, string>(
  R.minBy(statusPriority),
  'empty',
);

const extractStatuses = R.map(R.prop('status'));

const rewriteTimedoutToFailed = R.map(
  R.when(R.equals('timedout'), R.always('failed')),
);

interface AggregatableAction {
  status: string;
  insignificant?: boolean;
}

export const aggregateStatus = R.pipe<
  [AggregatableAction[]],
  AggregatableAction[],
  AggregatableAction[],
  string[],
  string[],
  string,
  string
>(
  R.reject(R.isNil),
  rejectInsignificantActions,
  extractStatuses,
  rewriteTimedoutToFailed,
  findMostImportantStatus,
  R.toUpper,
);

const actionsByParallelRun = <T extends { index: number }>(
  steps: { actions: T[] }[],
): { [key: string]: T[] } => {
  const nestedActions = R.map(R.prop('actions'), steps);
  const actions = R.unnest(nestedActions);
  return R.groupBy(propIndexToString, actions);
};

export const parallelRuns = (
  cacheKey: string,
  apiSteps: APIStep[],
): ParallelRun[] => {
  const runs: { [index: string]: ParallelRun } = {};

  const actionsGroupedByParallelRun = R.pipe<
    [APIStep[]],
    { [key: string]: APIAction[] },
    { [key: string]: APIAction[] }
  >(
    actionsByParallelRun,
    R.mapObjIndexed(rejectInfraFailures),
  )(apiSteps);

  for (const index in actionsGroupedByParallelRun) {
    const actions = actionsGroupedByParallelRun[index];

    runs[index] = {
      id: `${cacheKey}_${index}`,
      slug: `${index}`,
      status: aggregateStatus(actions),
      steps: actions.map(
        ({
          name,
          end_time,
          index,
          allocation_id,
          insignificant,
          run_time_millis,
          start_time,
          status,
          step,
          exit_code,
          bash_command,
        }) => {
          return {
            id: `${cacheKey}_${index}_${step}`,
            slug: `${index}-${step}`,
            stepIndex: step,
            allocationId: allocation_id,
            insignificant,
            name: name,
            runtime: run_time_millis,
            startedAt: start_time,
            status: R.toUpper(status),
            stoppedAt: end_time,
            exitCode: exit_code,
            bashCommand: bash_command ? bash_command : undefined,
          };
        },
      ),
    };
  }

  return R.values(runs);
};

type AllocationAction = Pick<APIAction, 'index' | 'allocation_id'>;
type GroupedActions = {
  [index: string]: AllocationAction[];
};

const formatInfrastructureFailMessage = (index: string) => ({
  type: JobMessageType.info,
  reason: 'infrastructure-failure',
  message: `Parallel Run ${index}: There was an issue while running this parallel run and it was rerun. The most recent run is shown.`,
});

const countUniqAllocationIds = (actions: AllocationAction[]) => {
  const allocation_ids = R.map(R.prop('allocation_id'), actions);
  const uniq_allocation_ids = R.uniq(allocation_ids);
  return R.length(uniq_allocation_ids);
};

const findFailedParallelRunIndexes = (
  groupedActions: GroupedActions,
): string[] => {
  const failedGroups = R.pickBy<GroupedActions, GroupedActions>(
    (actions: AllocationAction[]) => countUniqAllocationIds(actions) > 1,
    groupedActions,
  );

  return R.keys(failedGroups) as string[];
};

interface InfraFailureStep {
  actions: AllocationAction[];
}

export const infrastructureFailMessages = (
  steps: InfraFailureStep[],
): JobMessage[] => {
  const groupedActions = actionsByParallelRun(steps);
  const failedIndexes = findFailedParallelRunIndexes(groupedActions);
  return R.map(formatInfrastructureFailMessage, failedIndexes);
};

const pullRequest = (apiPullRequest: APIPullRequest): PullRequest => ({
  headSHA: apiPullRequest.head_sha,
  url: apiPullRequest.url,
});
const pullRequests = R.map(pullRequest);

export const workflow = ({
  workflows,
  pull_requests,
}: APIJob): Workflow | null => {
  if (R.isNil(workflows)) return null;

  return {
    id: workflows.workflow_id,
    name: workflows.workflow_name,
    pullRequests: pullRequests(pull_requests),
  };
};

export const actor = ({ avatar_url, name, vcs_type, login }: APIActor) => {
  const baseUrl =
    (vcs_type === 'github' && global.circleci?.config?.gitHubHttpEndpoint) ||
    (vcs_type && `https://${vcs_type}.com`) ||
    undefined;
  const vcsUrl = baseUrl ? `${baseUrl}/${login}` : undefined;
  return {
    avatarUrl: avatar_url,
    name: name || login,
    vcsUrl,
  };
};

export const pipelinesEnabled = R.hasPath(['workflows']);

const commit = (apiCommit: APICommit): Commit => ({
  vcsRevision: apiCommit.commit,
  vcsUrl: apiCommit.commit_url,
});

export const commits = (apiCommits: APICommit[] = []) =>
  apiCommits ? R.map(commit, apiCommits) : [];

export const jobId = R.propOr(null, 'job_id');
export const jobName = R.propOr(null, 'job_name');

const messageType = (apiType: string): JobMessageType =>
  apiType in JobMessageType
    ? (apiType as JobMessageType)
    : JobMessageType.unknown;

const message = ({ message, reason, type }: APIJobMessage): JobMessage => ({
  message,
  reason,
  type: messageType(type),
});

export const messages = (input: APIJobMessage[] | null) => {
  return (input ?? []).map(message);
};

const terminalStatusList = [
  'success',
  'fixed',
  'failed',
  'canceled',
  'not_run',
  'no_tests',
  'timedout',
  'unauthorized',
];
export const isDone = (status: string) =>
  R.includes(status, terminalStatusList);

export const mapRam = (ram: number | null) =>
  ram == null
    ? null
    : parseFloat((Math.round((ram / 1024) * 2) / 2).toFixed(1));

const executorMap = {
  docker: 'Docker',
  remotedocker: 'Remote Docker',
  machine: 'Machine',
  linux: 'Machine',
  macos: 'MacOS',
  runner: 'Runner',
  windows: 'Machine',
};

export const mapExecutor = (executor: string) =>
  R.propOr(executor, executor)(executorMap) as string;

// This list is based on available resources here:
// hhttps://github.com/circleci/picard-services/blob/master/dispatcher/resources/dispatcher-definition.edn#L54-L61
const classMap = {
  'gpu.nvidia.small': 'GPU Linux Small',
  'gpu.nvidia.small.multi': 'Multi-GPU Linux Small',
  'gpu.nvidia.medium': 'GPU Linux Medium',
  'gpu.nvidia.medium.multi': 'Multi-GPU Linux Medium',
  'gpu.nvidia.large': 'GPU Linux Large',
  small: 'Small',
  medium: 'Medium',
  'medium+': 'Medium+',
  large: 'Large',
  xlarge: 'X-Large',
  '2xlarge': '2X-Large',
  '2xlarge+': '2X-Large+',
  'windows.medium': 'Windows Medium',
  'windows.large': 'Windows Large',
  'windows.xlarge': 'Windows X-Large',
  'windows.2xlarge': 'Windows 2X-Large',
  'windows.gpu.nvidia.medium': 'GPU Windows Medium',
};

export const mapClass = (className: string, executor: string) => {
  if (
    executor === 'machine' &&
    className.indexOf('windows') === -1 &&
    className.indexOf('gpu') === -1
  ) {
    return `Linux ${R.propOr(className, className)(classMap)}`;
  }
  return R.propOr(className, className)(classMap) as string;
};

const cacheKey = (jobId: string, buildNumber: number) =>
  `${jobId}/${buildNumber}`;

const mapAPIJobToGraphQL = (
  apiJob: APIJob,
  testSummaryResolver: () => Promise<TestSummary>,
  pipelineId: string | null,
): Job => ({
  usingNewStepsAPI: apiJob.using_new_steps_api,
  cacheKey: cacheKey(apiJob.workflows?.job_id as any, apiJob.build_num),
  actor: actor(apiJob.user) as any,
  buildNumber: apiJob.build_num,
  buildUrl: apiJob.build_url,
  commits: commits(apiJob.all_commit_details),
  commitsTruncated: apiJob.all_commit_details_truncated || false,
  id: jobId(apiJob.workflows),
  name: jobName(apiJob.workflows),
  parallelRuns: parallelRuns(
    cacheKey(apiJob.workflows?.job_id as any, apiJob.build_num),
    apiJob.steps,
  ),
  pipelinesEnabled: pipelinesEnabled(apiJob),
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  /* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
  startedAt: apiJob?.start_time!,
  usageQueuedAt: R.pathOr(null, ['usage_queued_at'], apiJob),
  queuedAt: R.pathOr(null, ['queued_at'], apiJob),
  done: isDone(apiJob.status),
  status: R.toUpper(apiJob.status),
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  /* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
  stoppedAt: apiJob?.stop_time!,
  testSummary: testSummaryResolver,
  workflow: workflow(apiJob)!,
  vcsUrl: apiJob.vcs_url,
  vcsRevision: apiJob.vcs_revision,
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  why: apiJob?.why!,
  branch: apiJob.branch,
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  pipelineId: pipelineId!,
  messages: R.concat(
    messages(apiJob.messages),
    infrastructureFailMessages(apiJob.steps),
  ),
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  /* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
  resourceCpu: apiJob?.picard?.resource_class?.cpu!,
  // The API provides us with a RAM value in megabits, but we want to show users gigabytes
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  resourceRam: mapRam(apiJob?.picard?.resource_class?.ram as number | null)!,
  resourceClass: mapClass(
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    apiJob?.picard?.resource_class?.class!,
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    apiJob?.picard?.executor!,
  ),
  resourceName: apiJob?.picard?.resource_class?.name ?? undefined,
  resourceExecutor: mapExecutor(apiJob?.picard?.executor as any),
  oss: !!apiJob.oss,
  retries: R.propOr([], 'retries', apiJob),
});

export default mapAPIJobToGraphQL;
