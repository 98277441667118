import { useEffect } from 'react';
import { trackAction } from '@circleci/analytics';

interface Props {
  loading: boolean;
  isInvited: boolean;
  isUnauthorized: boolean | undefined;
  page: string | undefined;
}

const useTrackClickedSharedInviteLink = ({
  loading,
  isInvited,
  isUnauthorized,
  page,
}: Props) => {
  useEffect(() => {
    if (loading || !isInvited) return;

    trackAction('clicked-shared-invite-link', {
      isUnauthorized: !!isUnauthorized,
      page,
    });
  }, [loading, isInvited, isUnauthorized]);
};

export default useTrackClickedSharedInviteLink;
