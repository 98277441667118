import { useFeatureFlag } from '@circleci/feature-flags';

import { useMeContext } from '~/contexts/MeContext';

const useOrgHomepageFeature = () => {
  const { me } = useMeContext();

  return useFeatureFlag({
    featureName: 'org_homepage',
    attributes: {
      userAnalyticsId: me?.id,
    },
  });
};

export default useOrgHomepageFeature;
