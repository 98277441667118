import { client } from '@circleci/web-ui-data';

import { APIV2ItemsResponse } from '~/project-settings/graphql/types/apiv2';
import { APIWebhook } from '~/project-settings/graphql/types/webhook';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  scopeId: string;
  scopeType: string;
}

export type APIWebhooksResponse = APIV2ItemsResponse<APIWebhook>;

export const exampleWebhooks: APIWebhooksResponse = {
  items: [
    {
      id: 'A24774FF-4573-4379-9F78-72C9F2EE30E6',
      name: 'Example Webhook',
      scope: {
        type: 'project',
        id: 'g46464-g4646-4464-jh566-3563gd',
      },
      events: ['workflow-completed'],
      url: 'https://example.com/hooks/circleci',
      verify_tls: true,
      created_at: '2021-04-07T16:03:14.810Z',
      updated_at: '2021-04-07T16:03:14.810Z',
    },
    {
      id: '3C58F3CC-4591-42E6-B508-76CE994B8161',
      name: 'Example Webhook 2',
      scope: {
        type: 'project',
        id: 'g46464-g4646-4464-jh566-3563gd',
      },
      events: ['workflow-completed'],
      url: 'https://example.com/hooks/circleci',
      verify_tls: false,
      created_at: '2021-04-07T16:03:14.810Z',
      updated_at: '2021-04-07T16:03:14.810Z',
    },
    {
      id: 'CAF87839-95AB-4A15-A3F2-E94E7E3C498E',
      name: 'Example Webhook 3',
      scope: {
        type: 'project',
        id: 'g46464-g4646-4464-jh566-3563gd',
      },
      events: ['workflow-completed'],
      url: 'https://example.com/hooks/circleci',
      verify_tls: true,
      created_at: '2021-04-07T16:03:14.810Z',
      updated_at: '2021-04-07T16:03:14.810Z',
    },
  ],
};

const fetchWebhooks = async ({
  apiKey,
  fetch,
  scopeId,
  scopeType,
}: Props): Promise<APIWebhooksResponse> => {
  const pathname = `/api/v2/webhook`;
  const query = {
    'scope-id': scopeId,
    'scope-type': scopeType,
  };
  const response = await client.apiFetcher({ apiKey, fetch, pathname, query });
  return await response.json();
};

export default fetchWebhooks;
