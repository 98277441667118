import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

const cancelWorkflow = async (
  fetch: Fetch,
  apiKey: string,
  workflowId: string,
) => {
  const pathname = `/api/v2/workflow/${workflowId}/cancel`;
  const response = await client.apiMutator({
    apiKey,
    fetch,
    pathname,
    callerName: 'cancelWorkflow',
  });

  const message = await response.json();
  return message?.message;
};

export default cancelWorkflow;
