import TPusher, { AuthorizerCallback } from 'pusher-js';
import * as R from 'ramda';
import unfetch from 'unfetch';

// pusher-js is exporting it's default constructor in a way that seems to break in jest, but works when we do an old school require.
// This was the easiest way to get it working in both the browser and Jest when pusher-js was at v5.1.1
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Pusher = require('pusher-js');

import { client } from '@circleci/web-ui-data';
import getIsEnterprise from '~/utils/getIsEnterprise';
import getHostname from '~/utils/getHostname';

export const authorizePusher = (channel: { name: string }) => ({
  authorize: async (socketId: string, callback: AuthorizerCallback) => {
    try {
      const apiOptions: client.ApiFetcherArgs = {
        fetch: R.has('fetch', window) ? fetch : unfetch,
        pathname: '/auth/pusher',
        callerName: 'authorizePusher',
        apiKey: localStorage.getItem('CIRCLE_TOKEN') || undefined,
        query: {
          channel_name: channel.name,
          socket_id: socketId,
        },
      };
      if (process.env.NODE_ENV === 'development') {
        apiOptions.urlOptions = {};
      }
      const response = await client.apiFetcher(apiOptions);
      callback(null, await response.json());
    } catch (error) {
      callback(new Error(`Error calling auth endpoint: ${error}`), {
        auth: '',
      });
    }
  },
});

interface JobChannelProps {
  buildNumber: number;
  vcsType: string;
  project: string;
  username: string;
}

interface ParallelRunChannelProps {
  buildNumber: number;
  vcsType: string;
  project: string;
  username: string;
  parallelRunSlug: string;
}

const jobChannelPrefix = ({
  vcsType,
  username,
  project,
  buildNumber,
}: JobChannelProps) =>
  `private-${username}@${project}@${buildNumber}@vcs-${vcsType}`;

export const jobChannelName = (props: JobChannelProps) =>
  `${jobChannelPrefix(props)}@all`;

export const parallelRunChannelName = (props: ParallelRunChannelProps) =>
  `${jobChannelPrefix(props)}@${props.parallelRunSlug}`;

export interface PusherWsConfig {
  wsHost?: string;
  wsPort?: number;
  wssPort?: number;
  encrypted?: boolean;
  enableStats?: boolean;
  enabledTransports?: string[];
}

const getPusherConfiguration = () => {
  const endPointConfig = getIsEnterprise()
    ? {
        wsHost: getHostname(),
        wsPort: 80,
        wssPort: 443,
        enableStats: false,
        enabledTransports: ['ws'],
      }
    : {};
  return { authorizer: authorizePusher, forceTLS: true, ...endPointConfig };
};
export const createPusherWith = (appKey: string) => (): TPusher =>
  new Pusher(appKey, getPusherConfiguration());

export const userChannelName = (pusherId: string) => `private-${pusherId}`;
