import { APIScheduledTrigger } from '~/project-settings/packages/triggers/graphql/fetchers/fetchScheduledTrigger';
import { mapScheduledTrigger } from '~/project-settings/packages/triggers/graphql/mappers/mapScheduledTrigger';
import { ScheduledTriggers } from '~/project-settings/packages/triggers/graphql/types';

export const mapScheduledTriggersList = (
  apiScheduledTrigger: APIScheduledTrigger[],
  next_page_token?: string,
): ScheduledTriggers => ({
  items: apiScheduledTrigger.map((apiTrigger) =>
    mapScheduledTrigger(apiTrigger),
  ),
  nextPageToken: next_page_token,
});
