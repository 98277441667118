import { client } from '@circleci/web-ui-data';

import { VCSType } from '~/graphql/shim/types';
import { LatestConfig } from '~/graphql/shim/types';

interface Params {
  vcsType: VCSType;
  orgName: string;
  projectName: string;
  branchName: string;
}

const latestConfig = async (
  fetch: any,
  apiKey: string | undefined,
  { vcsType, orgName, projectName, branchName }: Params,
): Promise<LatestConfig> => {
  const pathname = `/api/v1.1/project/${vcsType}/${orgName}/${projectName}/config`;
  const rawResponse = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    query: { identifier: branchName },
  });
  return await rawResponse.json();
};

export default latestConfig;
