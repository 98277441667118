import * as React from 'react';
import useOnlineStatus from '@rehooks/online-status';

export enum ConnectivityStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  RECONNECTED = 'RECONNECTED',
}

const initialStatus = (isOnline: boolean) =>
  isOnline ? ConnectivityStatus.ONLINE : ConnectivityStatus.OFFLINE;

const updatedStatus = (isOnline: boolean) =>
  isOnline ? ConnectivityStatus.RECONNECTED : ConnectivityStatus.OFFLINE;

const useConnectivityStatus = () => {
  const isOnline = useOnlineStatus();
  const [status, setStatus] = React.useState(initialStatus(isOnline));

  React.useEffect(() => {
    if (isOnline && status === ConnectivityStatus.ONLINE) return;

    setStatus(updatedStatus(isOnline));
  }, [isOnline]);

  return status;
};

export default useConnectivityStatus;
