import { client } from '@circleci/web-ui-data';

import { APIWebhook } from '~/project-settings/graphql/types/webhook';

interface Props {
  apiKey: string;
  fetch: typeof window.fetch;
  id: string;
}

const fetchWebhook = async ({
  apiKey,
  fetch,
  id,
}: Props): Promise<APIWebhook> => {
  const pathname = `/api/v2/webhook/${id}`;
  const response = await client.apiFetcher({ apiKey, fetch, pathname });
  return await response.json();
};

export default fetchWebhook;
