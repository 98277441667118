import * as url from 'url';
import getHostname from '~/utils/getHostname';
import getIsEnterprise from '~/utils/getIsEnterprise';

interface Props {
  returnTo: string;
}

const getInviteSignupUrl = ({ returnTo = null as any }: Props) => {
  const defaultReturnTo = `https://app.${getHostname()}/dashboard`;
  return url.format({
    protocol: 'https:',
    hostname: getHostname(),
    pathname: getIsEnterprise() ? '/' : '/signup/',
    query: {
      'source-button': 'invite-my-team',
      'return-to': returnTo || defaultReturnTo,
    },
  });
};

export default getInviteSignupUrl;
