import { client } from '@circleci/web-ui-data';
import getHostname from '~/utils/getHostname';

interface Props {
  apiKey: string | undefined;
  fetch: typeof window.fetch;
}

const userRole = async ({ apiKey, fetch }: Props) => {
  const hostname = `bff.${getHostname()}`;
  const pathname = '/private/onboarding-service/user-attributes';
  const urlOptions = { hostname };
  const res = await client.apiFetcher({ apiKey, fetch, pathname, urlOptions });
  const { role } = await res.json();
  return { role };
};

export default userRole;
