import * as React from 'react';

import { useRouter } from 'next/router';

import sanitizeReturnTo from '~/project-settings/utils/sanitizeReturnTo';

interface ContextProps {
  returnTo: string;
}

interface Props {
  children: React.ReactNode;
}

const ReturnToContext = React.createContext<ContextProps>(null as any);
const defaultReturnTo = '/';

export const ReturnToProvider = ({ children }: Props) => {
  const router = useRouter();
  const rawReturnTo = router.query['return-to'] as string; // query returns string | string[]
  const returnTo = sanitizeReturnTo(rawReturnTo) || defaultReturnTo;

  return (
    <ReturnToContext.Provider value={{ returnTo }}>
      {children}
    </ReturnToContext.Provider>
  );
};

export const useReturnToContext = () =>
  React.useContext<ContextProps>(ReturnToContext);
export default ReturnToContext;
