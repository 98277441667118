import { ApiEnvironmentVariable } from '~/project-settings/graphql/shim/fetchers/environmentVariables';
import { EnvironmentVariable } from '~/project-settings/graphql/types';

const mapEnvironmentVariables = (
  environmentVariables: ApiEnvironmentVariable[],
): EnvironmentVariable[] =>
  environmentVariables.map((item) => ({
    name: item.name,
    value: item.value,
    createdAt: item?.created_at,
  }));

export default mapEnvironmentVariables;
