import { APIWorkflowJobsResponse } from '~/graphql/shim/types';
import { client } from '@circleci/web-ui-data';

type Fetch = typeof fetch;

const fetchWorkflowJobs = async (
  fetch: Fetch,
  apiKey: string,
  workflowId: string,
): Promise<APIWorkflowJobsResponse> => {
  const pathname = `/api/v2/workflow/${workflowId}/job`;
  const response = await client.apiFetcher({
    apiKey,
    fetch,
    pathname,
    callerName: 'fetchWorkflowJobs',
  });
  return await response.json();
};

export default fetchWorkflowJobs;
